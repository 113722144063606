const dataSizes = {
    sizes:[
        {
            id:1,
            info:[
                "- Дължина - 10м",
                "- Външна ширина - 3.30м",
                "- Вътрешна ширина - 3.00м",
                "- Дебелина на стената - 16см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина на високата страна - 2.50м",
                "- Вътрешна полезна височина на ниската страна - 2.30м",
                "- Спалня - 3х3м",
                "- Баня с вградена структура (скрито казанче в стената) - 2.20х.1.20м"               
            ]
        },
        {
            id:2,
            info:[
                "- Дължина - 10м",
                "- Външна ширина - 3.00м",
                "- Вътрешна ширина - 2.90м",
                "- Дебелина на стената - 11см",
                "- Височина от гумите до най-висока точка на къщата - 3.70м",
                "- Вътрешна полезна височина на високата страна - 2.60м",
                "- Вътрешна полезна височина на ниската страна - 2.40м",
                "- Спалня - 2.90х2.44м",
                "- Втора спалня - 2.90х2.44м",
                "- Баня с моноблок - 1.80х.1.40м"  
            ]
        },
        {
            id:3,
            info:[
                "- Дължина - 10м",
                "- Външна ширина - 3.30м",
                "- Вътрешна ширина - 3.00м",
                "- Дебелина на стената - 16см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина на високата страна - 2.50м",
                "- Вътрешна полезна височина на ниската страна - 2.30м",
                "- Спалня - 3х3м",
                "- Баня с моноблок - 2.20х.1.20м"
            ]
        },
        {
            id:4,
            info:[
                "- Дължина - 12м",
                "- Външна ширина - 3.30м",
                "- Вътрешна ширина - 3.00м",
                "- Дебелина на стената - 16см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина на високата страна - 2.50м",
                "- Вътрешна полезна височина на ниската страна - 2.30м",
                "- Спалня - 3х3м",
                "- Втора спалня - 3х3м",
                "- Баня с вградена структура (скрито казанче в стената) - 2.20х.1.20м"
            ]
        },
        {
            id:5,
            info:[
                "- Дължина - 10м",
                "- Външна ширина - 3.30м",
                "- Вътрешна ширина - 3.00м",
                "- Дебелина на стената - 16см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина на високата страна - 2.50м",
                "- Вътрешна полезна височина на ниската страна - 2.30м",
                "- Спалня - 3х3м",
                "- Втора спалня - 3х3м",
                "- Баня с моноблок - 2.20х.1.20м"             
            ]
        },
        {
            id:6,
            info:[
                "- Дължина - 7.5м",
                "- Външна ширина - 3.30м",
                "- Вътрешна ширина - 3.00м",
                "- Дебелина на стената - 16см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина на високата страна - 2.50м",
                "- Вътрешна полезна височина на ниската страна - 2.30м",
                "- Спалня - 3х2.50м",
                "- Баня с вградена структура (скрито казанче в стената) - 2.20х.1.20м"
            ]
        },
        {
            id:7,
            info:[
                "- Обща дължина – 12.00м",
                "- Външна ширина – 3.30м",
                "- Вътрешна ширина - 3.00м",
                "- Дебелина на стената - 16см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина на високата страна - 2.50м",
                "- Вътрешна полезна височина на ниската страна - 2.30м",
                "- Спалня – 4.20х3.00м",
                "- Всекидневна – 6х3.00м",
                "- Баня с вгадена структура и биде (скрито казанче в стената) и вана – 1.70х2.00м"
            ]
        },
        {
            id:8,
            info:[
                "- Обща дължина – 10.00м",
                "- Външна ширина – 3.30м",
                "- Вътрешна ширина – 3.00м",
                "- Дебелина на стената - 16см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина на високата страна - 2.50м",
                "- Вътрешна полезна височина на ниската страна - 2.30м",
                "- Спалня – 3.20х3.00м",
                "- Всекидневна – 5х3.00м",
                "- Баня със стъклен параван, моноблок – 1.60х3.00м"
            ]
        },
        {
            id:9,
            info:[
                "- Обща дължина – 9.12м",
                "- Външна ширина – 3.32м",
                "- Вътрешна ширина – 2.96м",
                "- Дебелина на стената – 19см",
                "- Височина от гумите до най-висока точка на къщата - 3.60м",
                "- Вътрешна полезна височина – 2.40м",
                "- Спалня – 2.50х2.96м",
                "- Всекидневна – 4.50х2.96м",
                "- Баня с вгадена структура и биде (скрито казанче в стената) и вана – 1.50х2.94м"
            ]
        }

    ]
}

export default dataSizes