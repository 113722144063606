import Landing from '../Images/Landing.webp'
import {ComponentsLandingImage, ContactUsMapContainer, ContactUsMainRowContainerMobile, ContactUsIFrame, ContactUsFormContainer,
   ContactUsFormLabel, ContactUsFormInput, ContactUsFormTextarea, CenterButtonContainer, MainButton, ContactUsHeaderRowContainer, 
   ContactUsRowContainerCol, ContactUsMainRowContainer, ContactUsHorizontalLine, ContactUsRowFirstTwoDetails} from '../Styles/ContainerStyles'
import {ComponentLandingHeader, MainHeader, CentereParagraphText, SecondaryHeader, ContactUsIcons,
  ContactUsFormHeaders, ContactUsIconBusiness} from '../Styles/HeaderStyles'
import { faLocationDot, faBusinessTime, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {useState, useEffect} from 'react';
import {db} from './../firebase'
import { getDatabase, ref, set, push } from "firebase/database";


function Contact() {

  const [name , setName] = useState();
  const [email , setEmail] = useState();
  const [phone , setPhone] = useState();
  const [city , setCity] = useState();
  const [message , setMessage] = useState();

  const handleSubmit = (event) => {
    event.preventDefault(); // prevent default form submission behavior
    // handle form submission here
    console.log("Form submitted with value: ");
  };

  const sendData = () => {
    console.log('entered function')
      const db = getDatabase();
      push(ref(db, 'email/' ), {
        name: name,
        email: email,
        phone : phone,
        city: city,
        message: message
      })
    .then(() => {
      setName('')
      setEmail('')
      setPhone('')
      setCity('')
      setMessage('')
      alert('Email was sent successfuly')
    })
    .catch((error) => {
      alert('Something went wrong please try again')
      console.log("there was an error", error)
    })
  }

    return (
      <div>
        <ComponentsLandingImage src={Landing} />
        <ComponentLandingHeader>Контакт</ComponentLandingHeader>
        <MainHeader>Контакт детайли</MainHeader>

        <ContactUsMainRowContainer>

            <ContactUsRowContainerCol>
              <ContactUsHeaderRowContainer>
                <ContactUsIcons fontSize={'23px'} icon={faLocationDot} />
                <SecondaryHeader marginTop={'0'} paddingLeft={'3%'} marginBottom={'0'}>Адрес</SecondaryHeader>
              </ContactUsHeaderRowContainer>
              <CentereParagraphText marginTop={'0'} marginBottom={'0'}>България, Харманли</CentereParagraphText>
              <CentereParagraphText marginTop={'0'}>бул. България 119</CentereParagraphText>
            </ContactUsRowContainerCol>

            <ContactUsRowContainerCol>
              <ContactUsHeaderRowContainer>
                <ContactUsIcons icon={faBusinessTime} />
                <SecondaryHeader marginTop={'0'} paddingLeft={'2%'} marginBottom={'0'}>Работно Време</SecondaryHeader>
              </ContactUsHeaderRowContainer>
              <CentereParagraphText marginTop={'0'} marginBottom={'0'}>Пон-Съб: 09:00 - 20:00</CentereParagraphText>
              <CentereParagraphText marginTop={'0'}>Нед: 11:00 - 17:00</CentereParagraphText>
            </ContactUsRowContainerCol>

            <ContactUsRowContainerCol>
              <ContactUsHeaderRowContainer>
                <ContactUsIcons icon={faPhone} />
                <SecondaryHeader marginTop={'0'} paddingLeft={'3.1%'} marginBottom={'0'}>Телефон</SecondaryHeader>
              </ContactUsHeaderRowContainer>
              <CentereParagraphText marginTop={'0'} marginBottom={'0'}>+359 999 002 444</CentereParagraphText>
            </ContactUsRowContainerCol>

            <ContactUsRowContainerCol>
              <ContactUsHeaderRowContainer>
                <ContactUsIcons icon={faEnvelope} />
                <SecondaryHeader marginTop={'0'} paddingLeft={'3%'} marginBottom={'0'}>Имейл</SecondaryHeader>
              </ContactUsHeaderRowContainer>
              <CentereParagraphText marginTop={'0'} marginBottom={'0'}>office@shouseltd.bg</CentereParagraphText>
            </ContactUsRowContainerCol>

          </ContactUsMainRowContainer>

          <ContactUsMainRowContainerMobile>

            <ContactUsRowFirstTwoDetails>
              <ContactUsRowContainerCol>
                <ContactUsHeaderRowContainer>
                  <ContactUsIcons fontSize={'23px'} icon={faLocationDot} />
                  <SecondaryHeader marginTop={'0'} paddingLeft={'3%'} marginBottom={'0'}>Адрес</SecondaryHeader>
                </ContactUsHeaderRowContainer>
                <CentereParagraphText marginTop={'0'} marginBottom={'0'}>България, Харманли</CentereParagraphText>
                <CentereParagraphText marginTop={'0'}>бул. България 119</CentereParagraphText>
              </ContactUsRowContainerCol>

              <ContactUsRowContainerCol>
                <ContactUsHeaderRowContainer>
                  <ContactUsIconBusiness icon={faBusinessTime} />
                  <SecondaryHeader marginTop={'0'} paddingLeft={'2%'} marginBottom={'0'}>Работно Време</SecondaryHeader>
                </ContactUsHeaderRowContainer>
                <CentereParagraphText marginTop={'0'} marginBottom={'0'}>Пон-Съб: 09:00 - 20:00</CentereParagraphText>
                <CentereParagraphText marginTop={'0'}>Нед: 11:00 - 17:00</CentereParagraphText>
              </ContactUsRowContainerCol>
            </ContactUsRowFirstTwoDetails>

            <ContactUsRowFirstTwoDetails>
              <ContactUsRowContainerCol>
                <ContactUsHeaderRowContainer>
                  <ContactUsIcons icon={faPhone} />
                  <SecondaryHeader marginTop={'0'} paddingLeft={'3.1%'} marginBottom={'0'}>Телефон</SecondaryHeader>
                </ContactUsHeaderRowContainer>
                <CentereParagraphText marginTop={'0'} marginBottom={'0'}>+359 999 002 444</CentereParagraphText>
              </ContactUsRowContainerCol>

              <ContactUsRowContainerCol>
                <ContactUsHeaderRowContainer>
                  <ContactUsIcons icon={faEnvelope} />
                  <SecondaryHeader marginTop={'0'} paddingLeft={'3%'} marginBottom={'0'}>Имейл</SecondaryHeader>
                </ContactUsHeaderRowContainer>
                <CentereParagraphText marginTop={'0'} marginBottom={'0'}>office@shouseltd.bg</CentereParagraphText>
              </ContactUsRowContainerCol>
            </ContactUsRowFirstTwoDetails>
        </ContactUsMainRowContainerMobile>

          <ContactUsHorizontalLine />
          <ContactUsMapContainer>
            <ContactUsIFrame  src="https://maps.google.com/maps?width=100%25&amp;height=900&amp;hl=en&amp;q=41.91919002908957,%2025.927468475851175+(%20%20S%20House%20LTD%20%D0%9C%D0%BE%D0%B1%D0%B8%D0%BB%D0%BD%D0%B8%20%D0%9A%D1%8A%D1%89%D0%B8)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" >
            <a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a>
            </ContactUsIFrame>
          </ContactUsMapContainer>
          

        <MainHeader>Контакт форма</MainHeader>
        <ContactUsFormContainer>
        <form onSubmit={handleSubmit}>
            <ContactUsFormLabel>
              <ContactUsFormHeaders>Име</ContactUsFormHeaders>
            <ContactUsFormInput value={name} type="text" name="name" onChange={(e) => setName(e.target.value)} />
          </ContactUsFormLabel>

          <ContactUsFormLabel>
              <ContactUsFormHeaders>Имейл</ContactUsFormHeaders>
            <ContactUsFormInput value={email} type="text" name="email" onChange={(e) => setEmail(e.target.value)}/>
          </ContactUsFormLabel>

          <ContactUsFormLabel>
              <ContactUsFormHeaders>Телефон</ContactUsFormHeaders>
            <ContactUsFormInput  value={phone} type="text" name="phone" onChange={(e) => setPhone(e.target.value)} />
          </ContactUsFormLabel>

          <ContactUsFormLabel>
              <ContactUsFormHeaders>Град</ContactUsFormHeaders>
            <ContactUsFormInput value={city} type="text" name="city" onChange={(e) => setCity(e.target.value)}/>
          </ContactUsFormLabel>

          <ContactUsFormLabel>
              <ContactUsFormHeaders>Съобщение</ContactUsFormHeaders>
            <ContactUsFormTextarea  value={message} type="textarea" name="message" onChange={(e) => setMessage(e.target.value)}/>
          </ContactUsFormLabel>
          <CenterButtonContainer marginBottom={'3%'} marginTop={'5%'}>
            <MainButton type="submit" onClick={()=>sendData()}>Изпрати</MainButton>
          </CenterButtonContainer>
        </form>
        </ContactUsFormContainer>
      </div>
    );
  }
  
  export default Contact;