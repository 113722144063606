import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const API_KEY = process.env.API_KEY
  const firebaseConfig = {
    apiKey: API_KEY,
    authDomain: "fir-house-c5ad1.firebaseapp.com",
    databaseURL: "https://fir-house-c5ad1-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "fir-house-c5ad1",
    storageBucket: "fir-house-c5ad1.appspot.com",
    messagingSenderId: "361010611114",
    appId: "1:361010611114:web:7ef534a765239be76be1d3",
    measurementId: "G-YFFN3WPRMS"
  };

const app = initializeApp(firebaseConfig)  
const database = getDatabase(app);