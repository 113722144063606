import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Global Styles
export const MainHeader = styled.h1`
    text-align:center;
    font-size:30px;
    font-weight:600;
    padding-top:${props => props.paddingTop};
    color:${props => props.colorProp};
    position:${props => props.positionProp};
    margin-top:${props => props.marginTop};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};

    @media only screen and (max-width:541px) and (min-width:320px){
       
    }
`
export const ComponentLandingHeader = styled.h1`
    text-align:center;
    position:absolute;
    font-size:35px;
    margin-top:-17%;
    margin-left:45%;
    color:white;
    font-weight:700;

    @media only screen and (max-width:541px) and (min-width:320px){
        margin-top:-70%;
        margin-left:35%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        font-size:40px;
        margin-top:-50%;
        margin-left:42.5%;
    }
`
export const SecondaryHeader = styled.h1`
    text-align:center;
    font-size:20px;
    margin-left:${props => props.marginLeft};
    width:${props => props.width};
    color:${props => props.colorProp};
    position:${props => props.positionProp};
    margin-top:${props => props.marginTop};
    text-align:${props => props.textAlign};
    padding-left:${props => props.paddingLeft};
    padding-right:${props => props.paddingRight};
    padding-top:${props => props.paddingTop};
    margin-bottom:${props => props.marginBottom};
    font-weight:600;
    font-weight:${props => props.fontWeight};
`
export const AccordionSpan = styled.span`
    color:#0C1B2A;
`
//-----------Navigation Styles--------------
export const NavigationHamburgerIcon = styled(FontAwesomeIcon)`
    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:32px;
        color:white;
        padding-right:0px;
        margin-right:0px;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        font-size:40px;
        color:white;
        padding-right:0px;
        margin-right:0px;
    }
`
export const NavigationLogoHeader = styled(Link)``

export const NavHeader = styled(Link)`
    font-size:16px;
    text-decoration:none;
    color:white;
    margin-right:15px;
    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:24px;
        text-decoration:none;
        color:white;
        margin-bottom:3%;
        margin-right:15px;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        font-size:28px;
        text-decoration:none;
        color:white;
        margin-bottom:3%;
        margin-right:15px;
    }
`
//----------Navigation Styles End---------
//--------------Home Styles---------------
//Home Landing Component Headers
export const HomeLandingComponentMainHeader = styled.h1`
    color:white;
    font-size:35px;
    font-weight:600;
    margin-top:1%;

    @media only screen and (max-width:541px) and (min-width:320px){
       font-size:20px;
    }
`
export const HomeLandingComponentSecondaryHeader = styled.h1`
    color:white;
    font-size:30px;
    font-weight:600;
    margin-bottom:0;

    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:15px;
    }
`
export const CentereParagraphText = styled.h2`
    font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;

    @media only screen and (max-width:1201px) and (min-width:542px){
       text-align:center;
    }
`
export const ImagesHeader = styled.h1`
    font-size:15px;
    font-weight:500;
    margin-top:0%;
    padding-top:0;

    @media only screen and (max-width:1201px) and (min-width:542px){
        font-size:20px;
    }
`
//Home Chose Us Component
export const ChoseUsParagraphText = styled.h2`
    font-size:15px;
    text-align:center;
    font-weight:400;
    width:80%;
    margin-left:10%;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:90%;
       margin-left:5%;
       margin-top:7%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       width:90%;
       margin-left:5%;
       margin-top:3%;
       font-size:20px;
    }
`
//Home About us Component
export const HomeAboutUsHeaderDesc = styled.h1`
    font-size:25px;
    margin-top:2%;
    padding-left:4%;
`
export const HomeAboutUsParagraphText = styled.h2`
    font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:90%;
       margin-left:5%;
       margin-top:-2%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       width:80%;
       margin-left:10%;
       margin-top:-2%;
       font-size:20px;
    }
`
//Home Testimonials Styles
export const MainHeaderTestimonials = styled.h1`
    text-align:center;
    font-size:30px;
    font-weight:600;
    padding-top:${props => props.paddingTop};
    color:${props => props.colorProp};
    position:${props => props.positionProp};
    margin-top:${props => props.marginTop};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};

    @media only screen and (max-width:541px) and (min-width:320px){
       width:80%;
       margin-left:10%;
    }
`
export const HomeTestimonialsParagraphText = styled.h2`
    font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;

    @media only screen and (max-width:1201px) and (min-width:542px){
       text-align:center;
       font-size:20px;
    }
`
//-------Home Component Styles End----------
//-------About Us Component Styles----------
export const MainHeaderAboutUsOpacityContainer = styled.h1`
    text-align:center;
    font-size:30px;
    font-weight:600;;
    color:white;
    margin-top:1%;
    width:60%;
    margin-left:20%;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:90%;
       margin-left:5%;
       font-size:22px;
    }
`
export const SecondaryHeaderAboutUsOpacityContainer = styled.h1`
    text-align:center;
    font-size:20px;
    margin-left:20%;
    width:60%;
    color:white;
    margin-top:0%;
    font-weight:600;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:90%;
       margin-left:5%;
       font-size:18px;
       margin-top:-3%;
    }
`
export const AboutUsCubesWithNumbersHeader = styled.h1`
    color:white;
    margin:0;
    padding:0;
    font-size:45px;
    text-align:center;

    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:35px;;
    }
`
export const CentereParagraphTextAboutUs = styled.h2`
    font-size:15px;
    text-align:center;
    width:50%;
    margin-left:25%;
    font-weight:400;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:80%;
       margin-left:10%;
       margin-top:3%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       width:80%;
       margin-left:10%;
       margin-top:3%;
       font-size:20px;
    }
`
export const CentereParagraphTextAboutServices = styled.h2`
     font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;

    @media only screen and (max-width:1201px) and (min-width:542px){
       text-align:center;
       font-size:20px;
    }
`
export const SecondaryHeaderAboutUsCol = styled.h1`
    text-align:center;
    font-size:20px;
    color:white;
    font-weight:600;

    @media only screen and (max-width:541px) and (min-width:320px){
       text-align:center;      
       margin-top:2%;
    }

`
export const SecondaryHeaderAboutUsServices = styled.h1`
    text-align:center;
    font-size:20px;
    margin-left:${props => props.marginLeft};
    width:${props => props.width};
    color:${props => props.colorProp};
    position:${props => props.positionProp};
    margin-top:${props => props.marginTop};
    text-align:${props => props.textAlign};
    padding-left:${props => props.paddingLeft};
    padding-right:${props => props.paddingRight};
    padding-top:${props => props.paddingTop};
    margin-bottom:${props => props.marginBottom};
    font-weight:600;
    font-weight:${props => props.fontWeight};

    @media only screen and (max-width:1201px) and (min-width:542px){
       font-size:25px;
    }
`
//-------About Us Component Styles End------
//-------Services Component Styles----------
export const ServicesParagraphText = styled.h2`
    font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;

    @media only screen and (max-width:541px) and (min-width:320px){
       text-align:center;      
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       text-align:center; 
       font-size:20px;
    }
`
export const ServicesMainParagraphText =styled.h2`
    font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;

    @media only screen and (max-width:541px) and (min-width:320px){
        text-align:center;
        width:80%;
        margin-left:10%;      
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       text-align:center; 
       font-size:20px;
        width:80%;
        margin-left:10%; 
    }
    
`
export const ServicesSecondaryHeader = styled.h1`
    text-align:center;
    font-size:20px;
    margin-left:${props => props.marginLeft};
    width:${props => props.width};
    color:${props => props.colorProp};
    position:${props => props.positionProp};
    margin-top:${props => props.marginTop};
    text-align:${props => props.textAlign};
    padding-left:${props => props.paddingLeft};
    padding-right:${props => props.paddingRight};
    padding-top:${props => props.paddingTop};
    margin-bottom:${props => props.marginBottom};
    font-weight:600;
    font-weight:${props => props.fontWeight};

     @media only screen and (max-width:541px) and (min-width:320px){
       text-align:center;      
       margin-top:4%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       text-align:center; 
       font-size:25px;
       margin-top:4%;
    }
`
//-------Services Component Styles End------
//-------Individual Projects Component Styles---------
export const IndividualProjectsParagraphText = styled.h2`
    font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;
    padding-bottom:3%;
    
    @media only screen and (max-width:541px) and (min-width:320px){
       text-align:center;      
       margin-top:4%;
       padding-bottom:5%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       text-align:center; 
       font-size:20px;
       margin-top:4%;
       padding-bottom:3%;
    }
`

export const ParagraphTextIndividualProjectsAccordion = styled.h2`
    font-size:15px;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-top:${props => props.paddingTop};
    font-weight:400;

    @media only screen and (max-width:1201px) and (min-width:542px){ 
       font-size:20px;
    }
`

export const IndividualProjectsSecondaryHeader = styled.h2`
    margin-top:${props => props.marginTop};
`
//-------Individual Projects Component Styles End---------
//-------Contact Us Component Styles--------
export const ContactUsIcons = styled(FontAwesomeIcon)`
    font-size:20px;
    font-size:${props => props.fontSize};
`
export const ContactUsIconBusiness = styled(FontAwesomeIcon)`
    font-size:20px;
    font-size:${props => props.fontSize};

    @media only screen and (max-width:541px) and (min-width:320px){
       font-size:23px;
    }
`
export const ContactUsFormHeaders = styled.h1`
    font-size:20px;
    margin-top:0;
    margin-bottom:1%;
    font-weight:500;
`
//-------Contact Us Component Styles End----
//-------Footer styles----------------------
export const FooterHeaders = styled.h1`
    font-size:13px;
    font-weight:500;
    margin-top:0;
    color:white;  
    margin-bottom:0%;

    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:15px;
        color:white;  
        margin-bottom:0%;
    }

`
export const EmailLink = styled.a`
    text-decoration:none!important;
`
export const FooterMainHeader = styled.h1`
    font-size:18px;
    font-weight:600;
    margin-top:0;
    color:white;  
    margin-bottom:0%;

    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:20px;
        color:white;  
        margin-bottom:0%;
    } 
`
export const FooterFirstHeader = styled.h1`
    font-size:18px;
    font-weight:500;
    margin-top:0;
    color:white;  
    margin-bottom:0%;

    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:17px;
        color:white;  
        margin-bottom:0%;
    } 
`
export const FooterSecondHeader = styled.h1`
    font-size:20px;
    font-weight:700;
    margin-top:0.5%;
    color:white;  
    margin-bottom:0%;
    
    @media only screen and (max-width:541px) and (min-width:320px){
        font-size:22px;
        color:white;  
        margin-bottom:0%;
    } 
`
export const FooterSpan = styled.span`
    color:#B29362;
`
export const FooterLink = styled.a`
    margin-right:5%;
`
export const FooterIcons = styled(FontAwesomeIcon)`
    color:#B29362;
    margin-right:7.5%;
    font-size:1.7em;
`