import Navbar from './Components/Navbar'
import Footer from './Components/Footer'

function App() {
  return (
    <div>
      <Navbar />
      <Footer />
    </div>
  );
}

export default App;
