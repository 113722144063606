import {FooterContainer, FooterInnerContainer, FooterRowContainer, FooterRowContainerInner,
     RowContainerCol, FooterSocialIcons, FooterRowColumns, FooterRowMidColumn} from '../Styles/ContainerStyles'
import {FooterHeaders, FooterSpan, FooterIcons, FooterMainHeader, FooterFirstHeader, FooterSecondHeader, FooterLink, EmailLink} from '../Styles/HeaderStyles'
import {faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons'

function Footer() {
    return (
      <FooterContainer>
        <FooterInnerContainer>
            <FooterFirstHeader >Нека построим нещо страхотно заедно!</FooterFirstHeader>
            <EmailLink href="mailto:office@shouseltd.bg"><FooterSecondHeader>office@shouseltd.bg</FooterSecondHeader></EmailLink>

            <FooterRowContainer>
                <FooterRowColumns>
                    <FooterMainHeader fontSize={'1em'} fontWeight={'700'} marginTop={'0'}>
                        <FooterSpan>/ </FooterSpan>
                        <span> Контакт</span>
                    </FooterMainHeader>
                    <FooterHeaders fontSize={'0.8em'} fontWeight={'400'} marginTop={'0'}>България</FooterHeaders>
                    <FooterHeaders fontSize={'0.8em'} fontWeight={'400'} marginTop={'0'}>гр. Харманли, бул. България 119</FooterHeaders>
                    <FooterHeaders fontSize={'0.8em'} fontWeight={'400'} marginTop={'0'}>+359 999 002 444</FooterHeaders>
                </FooterRowColumns>

                <FooterRowMidColumn >
                    <FooterMainHeader fontSize={'1em'} fontWeight={'700'} marginTop={'0'}>
                        <FooterSpan>/ </FooterSpan>
                        <span> Административна информация</span>
                    </FooterMainHeader>
                    <FooterHeaders fontSize={'0.8em'} fontWeight={'400'} marginTop={'0'}>Име: ЕС ХАУС ЕООД</FooterHeaders>
                    <FooterHeaders fontSize={'0.8em'} fontWeight={'400'} marginTop={'0'}>ЕИК: BG201870593</FooterHeaders>
                    <FooterHeaders fontSize={'0.8em'} fontWeight={'400'} marginTop={'0'}>IBAN: BG08RZBB91551012774406</FooterHeaders>
                </FooterRowMidColumn>

                <FooterRowColumns>
                    <FooterMainHeader>
                        <FooterSpan>/ </FooterSpan>
                        <span> Последвйте ни</span>
                    </FooterMainHeader>
                    <FooterRowContainerInner>
                        <FooterLink href="https://www.facebook.com/shouse.shouse.16" target="_blank"><FooterIcons icon={faFacebook}/></FooterLink>
                        <FooterLink href="https://www.instagram.com/s_house_ltd/" target="_blank"><FooterIcons icon={faInstagram}/></FooterLink>
                    </FooterRowContainerInner>
                </FooterRowColumns>

            </FooterRowContainer>
        </FooterInnerContainer>
      </FooterContainer>
    );
  }
  
  export default Footer;