import Landing from '../Images/AboutUsLandingOpacity.png'
import {ComponentsLandingImage, HomeContactUsContainer, MainButton,
  HorizontalLineAboutUs,AboutUsServicesImageContainer, AboutUsServicesImage, AboutUsOpacityImageContainer,
  AboutUsOpacityImage, AboutUsAcordionContainer, AboutUsAccordionDetails, ExpandMoreIconColor,
  RowContainerAboutUsComponent, AboutUsRowContainerCol, RowContainerAboutUsOpacity, RowContainerColAboutUsOpacity, 
  AboutUsOpacityContainerCubesWithNumbers, AboutUsOpacityHeadersContainer} from '../Styles/ContainerStyles'
import {ComponentLandingHeader, MainHeader, ParagraphTextIndividualProjectsAccordion, AboutUsCubesWithNumbersHeader,
  CentereParagraphTextAboutUs, MainHeaderAboutUsOpacityContainer, SecondaryHeaderAboutUsOpacityContainer,
   SecondaryHeaderAboutUsCol, CentereParagraphTextAboutServices, SecondaryHeaderAboutUsServices} from '../Styles/HeaderStyles'
import {useNavigate} from 'react-router-dom';
import ProductDesign from '../Images/ProductDesign.svg'
import ProfessionalImplementation from '../Images/ProfessionalImplementation.svg'
import AboutUsOpacity from '../Images/AboutUsOpacity2.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';


function About() {
    const navigate = useNavigate();
    const navigateToContact = () => {
        navigate(`/contact`)
    }
    return (
      <div>
        <ComponentsLandingImage src={Landing} />
        <ComponentLandingHeader>За нас</ComponentLandingHeader>
        <MainHeader>Ние сме S House</MainHeader>
        <CentereParagraphTextAboutUs>
          Мобилните къщи са изградени от дървена конструкция върху метално шаси с гуми като дължината може да варира през 10см, 
          започващи от 2 метра до максималната дължина от 12 метра. Ширината е ограничена до 3.30м (330см) външен габаритен размер, 
          задари извънгабаритни ограничения при транспорт.  Конструкцията се изготвя от Австрийска сертифицирана KVH C24 60х100мм 
          строителна дървесина, конструктивна обшивка от OSB3 плоскости с дебелина 11мм, паропропусклива мембрана, дървени летвички 
          за активна вентилируема фасада 2см, външна фасада/ винилов сайдинг, дървена ламперия. От вътрешната страна за изолация поставяме 
          минерална вата, каменна вата или полиуретанова пяна спрямо Вашият избор. 
          Интериорът може да бъде ламиниран паркет, дървена ламперия или винилови пана.
        </CentereParagraphTextAboutUs>
        <RowContainerAboutUsComponent marginTop={'5%'}>
          <AboutUsRowContainerCol>
            <AboutUsServicesImageContainer>
                <AboutUsServicesImage src={ProductDesign} />
            </AboutUsServicesImageContainer>
            <SecondaryHeaderAboutUsServices>Професионален дизайн</SecondaryHeaderAboutUsServices>
            <CentereParagraphTextAboutServices width={'70%'} marginLeft={'15%'} textAlign={'center'}>
            Нашият екип е посветен на изработването на домове по поръчка, 
            които идеално отговарят на вашия начин на живот и нужди. От оформлението и цветовата схема до материалите и облицовките, 
            ние обръщаме внимание на всеки детайл, за да гарантираме, че вашият дом е не само естетически, но и удобен и практичен. 
            Доверете ни се, за да превърнем вашия мечтан дом в реалност.
          </CentereParagraphTextAboutServices>
          </AboutUsRowContainerCol>

          <HorizontalLineAboutUs />
          <AboutUsRowContainerCol>
            <AboutUsServicesImageContainer>
                <AboutUsServicesImage src={ProfessionalImplementation} />
            </AboutUsServicesImageContainer>
            <SecondaryHeaderAboutUsServices>Професионално изпълнение</SecondaryHeaderAboutUsServices>
            <CentereParagraphTextAboutServices width={'70%'} marginLeft={'15%'} textAlign={'center'}>
              Ние вярваме, че безупречното изпълнение е ключът към създаването на висококачествени домове, които надхвърлят очакванията на нашите клиенти. 
              Нашият опитен екип от строители и изпълнители работи усърдно, за да гарантира, че всеки аспект от строителния процес е
              изпълнен до съвършенство. Ние използваме само най-добрите материали и усъвършенствани строителни техники, за да гарантираме,
              че вашият дом е издръжлив, енергийно ефективен и удобен за живеене.
          </CentereParagraphTextAboutServices>
          </AboutUsRowContainerCol>
          
        </RowContainerAboutUsComponent>        
        <AboutUsOpacityImageContainer>
          <AboutUsOpacityHeadersContainer>
            <MainHeaderAboutUsOpacityContainer>Доверете се на нашите професионалисти</MainHeaderAboutUsOpacityContainer>
            <SecondaryHeaderAboutUsOpacityContainer>Ние имаме огромен опит в изграждането на мобилни къщи</SecondaryHeaderAboutUsOpacityContainer>
          </AboutUsOpacityHeadersContainer>
          <AboutUsOpacityImage src={AboutUsOpacity}/>

          <RowContainerAboutUsOpacity>
            <RowContainerColAboutUsOpacity>
              <AboutUsOpacityContainerCubesWithNumbers>
                <AboutUsCubesWithNumbersHeader>7</AboutUsCubesWithNumbersHeader>
              </AboutUsOpacityContainerCubesWithNumbers>
              <SecondaryHeaderAboutUsCol>Завършени проекти</SecondaryHeaderAboutUsCol>
            </RowContainerColAboutUsOpacity>

            <RowContainerColAboutUsOpacity >
              <AboutUsOpacityContainerCubesWithNumbers>
                <AboutUsCubesWithNumbersHeader>10</AboutUsCubesWithNumbersHeader>
              </AboutUsOpacityContainerCubesWithNumbers>
              <SecondaryHeaderAboutUsCol>Щастливи клиенти</SecondaryHeaderAboutUsCol>
            </RowContainerColAboutUsOpacity >

            <RowContainerColAboutUsOpacity >
              <AboutUsOpacityContainerCubesWithNumbers>
                <AboutUsCubesWithNumbersHeader>5</AboutUsCubesWithNumbersHeader>
              </AboutUsOpacityContainerCubesWithNumbers>
              <SecondaryHeaderAboutUsCol>Години опит</SecondaryHeaderAboutUsCol>
            </RowContainerColAboutUsOpacity >
              
          </RowContainerAboutUsOpacity>
        </AboutUsOpacityImageContainer>

        <MainHeader marginTop={'3%'}>Сертификати</MainHeader>
        <AboutUsAcordionContainer>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIconColor />}
            aria-controls="panel1a-content" 
            id="panel1a-header">
            <Typography>Конструкция</Typography>
          </AccordionSummary>
          <AboutUsAccordionDetails>
            <Typography>
              <ParagraphTextIndividualProjectsAccordion>
                - Австрийска сертифицирана KVH C24 60х100мм строителна дървесина
              </ParagraphTextIndividualProjectsAccordion>
            </Typography>
          </AboutUsAccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIconColor />}
            aria-controls="panel1a-content" 
            id="panel1a-header">
            <Typography>Покрив</Typography>
          </AccordionSummary>
          <AboutUsAccordionDetails>
            <Typography>
              <ParagraphTextIndividualProjectsAccordion>
                - LT пластифицирана ламарина боядисана по цветова система
              </ParagraphTextIndividualProjectsAccordion>
              <ParagraphTextIndividualProjectsAccordion>
                - Термопанел
                </ParagraphTextIndividualProjectsAccordion>
            </Typography>
          </AboutUsAccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIconColor />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Изолация</Typography>
          </AccordionSummary>

          <AboutUsAccordionDetails>
            <Typography>
              <ParagraphTextIndividualProjectsAccordion>
                - Минерална вата 
              </ParagraphTextIndividualProjectsAccordion>
              <ParagraphTextIndividualProjectsAccordion>
                - Каменна вата  
              </ParagraphTextIndividualProjectsAccordion>
              <ParagraphTextIndividualProjectsAccordion>
                - Полиуретанова пяна 
              </ParagraphTextIndividualProjectsAccordion>
              <ParagraphTextIndividualProjectsAccordion>
                - ЕКО овча вълна 
              </ParagraphTextIndividualProjectsAccordion>
            </Typography>
          </AboutUsAccordionDetails>

        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIconColor />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Ел. е ВИК инсталации</Typography>
          </AccordionSummary>
          <AboutUsAccordionDetails>
            <Typography>
              <ParagraphTextIndividualProjectsAccordion>
                Мобилните къщи издаваме със завършени Ел. и ВИК инсталации.
              </ParagraphTextIndividualProjectsAccordion>
            </Typography>
          </AboutUsAccordionDetails>
        </Accordion>
      </AboutUsAcordionContainer>
        {/* Contact us Component */}
        <HomeContactUsContainer>
          <MainHeader>Харесвате това което виждате?</MainHeader>
          <MainButton onClick={() => navigateToContact()}>Свържете се</MainButton>
        </HomeContactUsContainer>
      </div>
    );
  }
  
  export default About;