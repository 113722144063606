import {RowContainer, RowContainerCol, MainButton, ChoseUsImage, ChooseUsRowContainer, ChooseUsColRight, ChooseUsColLeft,
   ChoseUsColDiv, ChoseUsColDivInner, HomeLandingImageComponent, HomeAboutUsContainer, 
   CenterButtonContainer, HomeAboutUsComponentNumbersRow,AboutUsCubesWithNumbers, HomeContactUsContainer,
   HomeRecentProjectsRowContainer, HomeRecentProjectsSmallCol, HomeRecentProjectsBigCol, HomeRecentProjectsBigColInnerRow, 
   HomeRecentProjectsImageContainer, HomeTestimonialsContainer, HomeTestimonialsSwiperContainer, HomeTestimonialsSwiperInnerContainer,
   HomeTestimonialsCircleBig, HomeTestimonialsCircleSmall, HomeQuotesImage, HorizontalLine,
   HomeLandingComponentContainer, TestimonialsRowContainer, HomeRowContainerCol, HomeRowContainerColMobile, HomeAboutUsImageContainer,
    HomeAboutUsButton, HomeAboutUsComponentNumbersCol} from '../Styles/ContainerStyles'
import {MainHeader, CentereParagraphText, ImagesHeader, AboutUsCubesWithNumbersHeader, SecondaryHeader,
  HomeLandingComponentMainHeader, HomeLandingComponentSecondaryHeader, ChoseUsParagraphText,
   HomeAboutUsParagraphText, MainHeaderTestimonials, HomeTestimonialsParagraphText} from '../Styles/HeaderStyles'
import HomeLandingImage from '../Images/HomeLanding.jpg'
import Idea from '../Images/Idea.svg'
import Planning from '../Images/Planning.svg'
import ProductDesign from '../Images/ProductDesign.svg'
import ProjectImplementation from '../Images/ProjectImplementation.svg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../index.css";
import BigQuotes from '../Images/BigQuotes.svg'
import SmallQuotes from '../Images/SmallQuotes.svg'
import data from '../Data/Projects'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {useNavigate} from 'react-router-dom';
import ReactPlayer from 'react-player'

function Home() {
   

    const navigate = useNavigate();
    const navigateToContact = () => {
        navigate(`/contact`)
    }

    const navigateToProjects = () => {
      navigate('/projects')
    }

    const navigateToAboutUs = () => {
      navigate('/about')
    }

    const navigateToIndividualProject = (id) => {
      navigate(`/individualproject/${id}`)
    }
    return (
      <div>
        {/* <HomeLandingImageComponent src={HomeLandingImage} /> */}
        <ReactPlayer 
          url='../video4.mp4'
          width='100%'
          height='100%'
          muted={true}
          playing={true}
          autoPlay={true}
          loop={true}
          playsinline={true}        
        />

        <HomeLandingComponentContainer>
          <HomeLandingComponentSecondaryHeader>КОМФОРТ НАД ВСИЧКО</HomeLandingComponentSecondaryHeader>
          <HomeLandingComponentMainHeader>СЪЗДАДЕНИ ЗА ЖИВОТ</HomeLandingComponentMainHeader>
          <MainButton onClick={() => navigateToContact()}>Свържете се</MainButton>
        </HomeLandingComponentContainer>
        <MainHeader>Защо да изберете нас?</MainHeader>
        <ChooseUsRowContainer >
            <ChooseUsColLeft textAlign={'center'}>
              <ChoseUsColDiv>
                <ChoseUsColDivInner>
                  <ChoseUsImage src={Idea} />
                  <ImagesHeader>Идея</ImagesHeader>
                </ChoseUsColDivInner>

                <ChoseUsColDivInner>
                  <ChoseUsImage src={Planning} />
                  <ImagesHeader>Планиране</ImagesHeader>
                </ChoseUsColDivInner>
              </ChoseUsColDiv>
                
              <ChoseUsColDiv>
                <ChoseUsColDivInner>
                  <ChoseUsImage src={ProductDesign} />
                  <ImagesHeader>Дизайн</ImagesHeader>
                </ChoseUsColDivInner>
                <ChoseUsColDivInner>
                  <ChoseUsImage src={ProjectImplementation} />
                  <ImagesHeader>Имплементация</ImagesHeader>
                </ChoseUsColDivInner>
                
              </ChoseUsColDiv>
            </ChooseUsColLeft>
            <ChooseUsColRight textAlign={'center'}>
              <ChoseUsParagraphText width={'80%'} marginLeft={'10%'}>
                Търсите компания за изграждане на мобилни къщи, която осигурява изключителни резултати? 
                Не търсете повече! С дългогодишен опит, напълно персонализиран дизайн на дома, висококачествени
                материали и отлично обслужване на клиентите,
                ние сме идеалният избор за вашия следващ проект за мобилна къща. Нека превърнем вашия мечтан дом в реалност!
              </ChoseUsParagraphText>
              <MainButton onClick={() => navigateToAboutUs()}>Научи повече</MainButton>
            </ChooseUsColRight>
        </ChooseUsRowContainer>

        {/* About us component */}
        <HomeAboutUsContainer>
          <MainHeader paddingTop={'1.5%'}>За нас</MainHeader>
          <HomeAboutUsParagraphText width={'50%'} marginLeft={'25%'} textAlign={'center'}>
            С дългогодишен опит, ангажираност към удовлетвореността на клиентите и използването само на най-добрите материали и строителни техники,
            ние сме посветени на създаването на красиви и висококачествени домове, които надхвърлят очакванията на нашите клиенти.
            Изберете нас за следващия си проект за мобилна къща и усетете разликата
          </HomeAboutUsParagraphText>

          <RowContainer width={'90%'} marginLeft={'5%'} marginTop={'5.5%'}>
            {/* Desktop Col */}
            <HomeRowContainerCol>
              <HomeAboutUsComponentNumbersRow marginBottom={'9.5%'}>
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>1</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Страст и креативност</SecondaryHeader>
              </HomeAboutUsComponentNumbersRow>

              <HomeAboutUsComponentNumbersRow marginBottom={'9.5%'}>
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>2</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Опит и ефективност</SecondaryHeader>
              </HomeAboutUsComponentNumbersRow>

              <HomeAboutUsComponentNumbersRow marginBottom={'9.5%'}>
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>3</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Внимание към детайлите</SecondaryHeader>
              </HomeAboutUsComponentNumbersRow>

              <HomeAboutUsComponentNumbersRow >
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>4</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Висококачествена изработка</SecondaryHeader>
              </HomeAboutUsComponentNumbersRow>
            </HomeRowContainerCol>

            {/* Mobile Col */}
            <HomeRowContainerColMobile>
              <HomeAboutUsComponentNumbersCol>
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>1</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Страст и креативност</SecondaryHeader>
              </HomeAboutUsComponentNumbersCol>

              <HomeAboutUsComponentNumbersCol>
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>2</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Опит и ефективност</SecondaryHeader>
              </HomeAboutUsComponentNumbersCol>

              <HomeAboutUsComponentNumbersCol>
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>3</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Внимание към детайлите</SecondaryHeader>
              </HomeAboutUsComponentNumbersCol>

              <HomeAboutUsComponentNumbersCol>
                <AboutUsCubesWithNumbers>
                  <AboutUsCubesWithNumbersHeader>4</AboutUsCubesWithNumbersHeader>
                </AboutUsCubesWithNumbers>
                <SecondaryHeader paddingLeft={'4%'}>Висококачествена изработка</SecondaryHeader>
              </HomeAboutUsComponentNumbersCol>
            </HomeRowContainerColMobile>

            <RowContainerCol>
              <HomeAboutUsImageContainer src={data.projects[0].mainImageUrl} width={'100%'} height={'54vh'}/>
            </RowContainerCol>
          </RowContainer>

          <CenterButtonContainer marginTop={'1.5%'} paddingBottom={'1%'}>
            <HomeAboutUsButton onClick={() => navigateToAboutUs()}>Научи повече</HomeAboutUsButton>
          </CenterButtonContainer>
        </HomeAboutUsContainer>
        {/* Recent Projects Component */}
        <MainHeader>Завършени проекти</MainHeader>
        <HomeRecentProjectsRowContainer>
          <HomeRecentProjectsSmallCol>
            <HomeRecentProjectsImageContainer onClick={() => navigateToIndividualProject(0)} src={data.projects[0].mainImageUrl} width={'100%'} marginTop={'60%'} cursorProp={'pointer'} height={'24vh'}/>
          </HomeRecentProjectsSmallCol>

          <HomeRecentProjectsBigCol>
             <HomeRecentProjectsBigColInnerRow marginBottom={'3%'}>
                <HomeRecentProjectsImageContainer onClick={() => navigateToIndividualProject(1)} src={data.projects[1].mainImageUrl} width={'65%'} cursorProp={'pointer'}/>
                <HomeRecentProjectsImageContainer onClick={() => navigateToIndividualProject(2)} src={data.projects[2].mainImageUrl} width={'32%'} height={'18vh'} marginLeft={'68%'}  cursorProp={'pointer'} position={'absolute'} bottomProp={'0'}/>
             </HomeRecentProjectsBigColInnerRow>

             <HomeRecentProjectsBigColInnerRow>
                <HomeRecentProjectsImageContainer onClick={() => navigateToIndividualProject(3)} src={data.projects[3].mainImageUrl} width={'32%'} height={'18vh'} marginRight={'3%'} cursorProp={'pointer'}/>
                <HomeRecentProjectsImageContainer onClick={() => navigateToIndividualProject(4)} src={data.projects[4].mainImageUrl} width={'65%'} cursorProp={'pointer'}/>
             </HomeRecentProjectsBigColInnerRow>
            
          </HomeRecentProjectsBigCol>

          <HomeRecentProjectsSmallCol>
            <HomeRecentProjectsImageContainer onClick={() => navigateToIndividualProject(5)} src={data.projects[5].mainImageUrl} width={'100%'} marginTop={'60%'} cursorProp={'pointer'} height={'24vh'}/>
          </HomeRecentProjectsSmallCol>
        </HomeRecentProjectsRowContainer>
        <CenterButtonContainer marginTop={'2%'}>
            <MainButton onClick={() => navigateToProjects()}>Проекти</MainButton>
        </CenterButtonContainer>

        

        {/* Testimonials Component */}
        <HomeTestimonialsContainer>
        <MainHeaderTestimonials paddingTop={'1.5%'}>Вижте какво мислят нашите клиенти</MainHeaderTestimonials>
          <TestimonialsRowContainer >
            <Swiper   
                      
                      breakpoints={{
                        // when window width is >= 640px
                        319: {
                          slidesPerView: 1,
                          spaceBetween: 50,
                        },
                        // 542: {
                        //   slidesPerView: 2,
                        //   spaceBetween: 30,
                        //   pagination:{
                        //     clickable: true,
                        //   },
                        //   modules:[Pagination]
                        // },
                        // when window width is >= 768px
                        1201: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                          pagination:{
                            clickable: true,
                          },
                          modules:[Pagination]
                        }
                      }}

                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper">
              <SwiperSlide>
                <HomeTestimonialsSwiperContainer>
                    <HomeTestimonialsCircleBig>
                      <HomeQuotesImage src={BigQuotes} width={'40%'} marginTop={'29%'}/>
                    </HomeTestimonialsCircleBig>
                    <HomeTestimonialsSwiperInnerContainer>
                      <SecondaryHeader textAlign={'left'} marginLeft={'5%'} paddingTop={'4%'} marginTop={'0'} marginBottom={'1.5%'}>Кристиян Милчев</SecondaryHeader>
                      <HorizontalLine width={'25%'} marginLeft={'5%'} marginTop={'0'}/>
                      <HomeTestimonialsParagraphText textAlign={'left'} width={'90%'} marginLeft={'5%'} paddingTop={'2%'}>
                      Изключително съм доволен от услугите, коректността и професионалното отношение на фирмата към клиента, 
                      препоръчвам с две ръце!
                      </HomeTestimonialsParagraphText>
                    </HomeTestimonialsSwiperInnerContainer>
                    <HomeTestimonialsCircleSmall>
                      <HomeQuotesImage src={SmallQuotes} width={'35%'} marginTop={'34%'}/>
                    </HomeTestimonialsCircleSmall>
                </HomeTestimonialsSwiperContainer>
              </SwiperSlide>

              <SwiperSlide>
                <HomeTestimonialsSwiperContainer>
                    <HomeTestimonialsCircleBig>
                      <HomeQuotesImage src={BigQuotes} width={'40%'} marginTop={'29%'}/>
                    </HomeTestimonialsCircleBig>
                    <HomeTestimonialsSwiperInnerContainer>
                      <SecondaryHeader textAlign={'left'} marginLeft={'5%'} paddingTop={'4%'} marginTop={'0'} marginBottom={'1.5%'}>Ангел Донков</SecondaryHeader>
                      <HorizontalLine width={'25%'} marginLeft={'5%'} marginTop={'0'}/>
                      <HomeTestimonialsParagraphText textAlign={'left'} width={'90%'} marginLeft={'5%'} paddingTop={'2%'}>
                      Най-коректната фирма отличаваща се с перфектно качество!
                      </HomeTestimonialsParagraphText>
                    </HomeTestimonialsSwiperInnerContainer>
                    <HomeTestimonialsCircleSmall>
                      <HomeQuotesImage src={SmallQuotes} width={'35%'} marginTop={'34%'}/>
                    </HomeTestimonialsCircleSmall>
                </HomeTestimonialsSwiperContainer>
              </SwiperSlide>

              <SwiperSlide>
                <HomeTestimonialsSwiperContainer>
                    <HomeTestimonialsCircleBig>
                      <HomeQuotesImage src={BigQuotes} width={'40%'} marginTop={'29%'}/>
                    </HomeTestimonialsCircleBig>
                    <HomeTestimonialsSwiperInnerContainer>
                      <SecondaryHeader textAlign={'left'} marginLeft={'5%'} paddingTop={'4%'} marginTop={'0'} marginBottom={'1.5%'}>Костадин Делчев</SecondaryHeader>
                      <HorizontalLine width={'25%'} marginLeft={'5%'} marginTop={'0'}/>
                      <HomeTestimonialsParagraphText textAlign={'left'} width={'90%'} marginLeft={'5%'} paddingTop={'2%'}>
                      Изключително съм доволен от услугите, коректността и професионалното отношение на фирмата към клиента, отлично качество!
                      </HomeTestimonialsParagraphText>
                    </HomeTestimonialsSwiperInnerContainer>
                    <HomeTestimonialsCircleSmall>
                      <HomeQuotesImage src={SmallQuotes} width={'35%'} marginTop={'34%'}/>
                    </HomeTestimonialsCircleSmall>
                </HomeTestimonialsSwiperContainer>
              </SwiperSlide>
              
              <SwiperSlide>
                <HomeTestimonialsSwiperContainer>
                    <HomeTestimonialsCircleBig>
                      <HomeQuotesImage src={BigQuotes} width={'40%'} marginTop={'29%'}/>
                    </HomeTestimonialsCircleBig>
                    <HomeTestimonialsSwiperInnerContainer>
                      <SecondaryHeader textAlign={'left'} marginLeft={'5%'} paddingTop={'4%'} marginTop={'0'} marginBottom={'1.5%'}>Даниел Налбантов</SecondaryHeader>
                      <HorizontalLine width={'25%'} marginLeft={'5%'} marginTop={'0'}/>
                      <HomeTestimonialsParagraphText textAlign={'left'} width={'90%'} marginLeft={'5%'} paddingTop={'2%'}>
                        Доста съм доволен от дърводелските услуги на фирмата, правят всичко по поръчка. Препоръчвам!!!
                      </HomeTestimonialsParagraphText>
                    </HomeTestimonialsSwiperInnerContainer>
                    <HomeTestimonialsCircleSmall>
                      <HomeQuotesImage src={SmallQuotes} width={'35%'} marginTop={'34%'}/>
                    </HomeTestimonialsCircleSmall>
                </HomeTestimonialsSwiperContainer>
              </SwiperSlide>

              {/* <SwiperSlide>
                <HomeTestimonialsSwiperContainer>
                    <HomeTestimonialsCircleBig>
                      <HomeQuotesImage src={BigQuotes} width={'40%'} marginTop={'29%'}/>
                    </HomeTestimonialsCircleBig>
                    <HomeTestimonialsSwiperInnerContainer>
                      <SecondaryHeader textAlign={'left'} marginLeft={'5%'} paddingTop={'4%'} marginTop={'0'} marginBottom={'1.5%'}>Атанас Димулски4</SecondaryHeader>
                      <HorizontalLine width={'25%'} marginLeft={'5%'} marginTop={'0'}/>
                      <CentereParagraphText textAlign={'left'} width={'90%'} marginLeft={'5%'} paddingTop={'2%'}>
                        Lorem ipsum dolor sit amet, consectetuer 
                        adipiscing elit, sed diam nonummy nibh 
                        euismod tincidunt ut laoreet dolore 
                        magna aliquam erat volutpat. Ut wisi enim 
                        ad minim veniam, quis nostrud exerci
                      </CentereParagraphText>
                    </HomeTestimonialsSwiperInnerContainer>
                    <HomeTestimonialsCircleSmall>
                      <HomeQuotesImage src={SmallQuotes} width={'35%'} marginTop={'34%'}/>
                    </HomeTestimonialsCircleSmall>
                </HomeTestimonialsSwiperContainer>
              </SwiperSlide>

              <SwiperSlide>
                <HomeTestimonialsSwiperContainer>
                    <HomeTestimonialsCircleBig>
                      <HomeQuotesImage src={BigQuotes} width={'40%'} marginTop={'29%'}/>
                    </HomeTestimonialsCircleBig>
                    <HomeTestimonialsSwiperInnerContainer>
                      <SecondaryHeader textAlign={'left'} marginLeft={'5%'} paddingTop={'4%'} marginTop={'0'} marginBottom={'1.5%'}>Атанас Димулски5</SecondaryHeader>
                      <HorizontalLine width={'25%'} marginLeft={'5%'} marginTop={'0'}/>
                      <CentereParagraphText textAlign={'left'} width={'90%'} marginLeft={'5%'} paddingTop={'2%'}>
                        Lorem ipsum dolor sit amet, consectetuer 
                        adipiscing elit, sed diam nonummy nibh 
                        euismod tincidunt ut laoreet dolore 
                        magna aliquam erat volutpat. Ut wisi enim 
                        ad minim veniam, quis nostrud exerci
                      </CentereParagraphText>
                    </HomeTestimonialsSwiperInnerContainer>
                    <HomeTestimonialsCircleSmall>
                      <HomeQuotesImage src={SmallQuotes} width={'35%'} marginTop={'34%'}/>
                    </HomeTestimonialsCircleSmall>
                </HomeTestimonialsSwiperContainer>
              </SwiperSlide> */}

            </Swiper>
          </TestimonialsRowContainer>
        </HomeTestimonialsContainer>
        {/* Contact us Component */}
        <HomeContactUsContainer>
          <MainHeader>Харесвате това което виждате?</MainHeader>
          <MainButton onClick={() =>navigateToContact()}>Свържете се</MainButton>
        </HomeContactUsContainer>
      </div>
    );
  }
  
  export default Home;