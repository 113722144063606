import { useState, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Contact from '../Components/Contact'
import About from '../Components/About'
import Home from '../Components/Home'
import Projects from '../Components/Projects'
import IndividualProject from '../Components/IndividualProject'
import {NavigationContainer, NavigationLogoContainer, NavLinkContainer, LogoImage, 
    NavigationContainerLandingComponent, NavigationContainerMobile, NavigationContainerLandingComponentMobile,
    NavigationHamburgerIconButton, NavigationMobileDrawerContainer, NavigationMobileDrawerLogoContainer, NavigationMobileDrawerLogoImage} from '../Styles/ContainerStyles'
import {NavigationLogoHeader, NavHeader, NavigationHamburgerIcon} from '../Styles/HeaderStyles'
import Logo from '../Images/logo.png'
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from '../Helpers/ScrollToTop'

function Navbar() {
    const [scrollTop, setScrollTop] = useState(0);
    const [displayMobileDrawer, setDisplayMobileDrawer] = useState('false')

    function toggleMobileDrawer() {
        setDisplayMobileDrawer(!displayMobileDrawer)
    }

    useEffect(() => {
        const handleScroll = (event) => {
        setScrollTop(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    
    return (
        <div>
            <Router>
                {scrollTop>300 ?               
                <NavigationContainer>
                    <NavigationLogoContainer>
                        <NavigationLogoHeader to="/"><LogoImage src={Logo}/></NavigationLogoHeader>
                    </NavigationLogoContainer>

                    <NavLinkContainer>
                        <NavHeader to="/">НАЧАЛО</NavHeader>
                        <NavHeader to="/about">ЗА НАС</NavHeader>
                        <NavHeader to="/projects">ПРОЕКТИ</NavHeader>
                        <NavHeader to="/contact">КОНТАКТИ</NavHeader>
                    </NavLinkContainer>
                </NavigationContainer>
                :
                <NavigationContainerLandingComponent>
                    <NavigationLogoContainer></NavigationLogoContainer>

                    <NavLinkContainer>
                        <NavHeader to="/">НАЧАЛО</NavHeader>
                        <NavHeader to="/about">ЗА НАС</NavHeader>
                        <NavHeader to="/projects">ПРОЕКТИ</NavHeader>
                        <NavHeader to="/contact">КОНТАКТИ</NavHeader>
                    </NavLinkContainer>
                </NavigationContainerLandingComponent>
                }

                {/* Mobile Phone Navigation */}
                {scrollTop>300 ? 
                <NavigationContainerMobile>
                    <NavigationLogoContainer>
                        <NavigationLogoHeader to="/"><LogoImage src={Logo} displayProp={displayMobileDrawer}/></NavigationLogoHeader>
                    </NavigationLogoContainer> 
                     <NavigationHamburgerIconButton onClick={()=>toggleMobileDrawer()}>
                        {displayMobileDrawer ?
                        <NavigationHamburgerIcon icon={faBars}/>
                        :
                        <NavigationHamburgerIcon icon={faTimes}/>
                        }
                    </NavigationHamburgerIconButton> 
                                          
                </NavigationContainerMobile>
                :
                
                 <NavigationContainerLandingComponentMobile>
                <NavigationLogoContainer></NavigationLogoContainer>
                    
                 <NavigationHamburgerIconButton onClick={()=>toggleMobileDrawer()}>
                      {displayMobileDrawer ?
                        <NavigationHamburgerIcon icon={faBars}/>
                        :
                        <NavigationHamburgerIcon icon={faTimes}/>
                        }
                  </NavigationHamburgerIconButton>  
                 </NavigationContainerLandingComponentMobile> 
                 }

                <NavigationMobileDrawerContainer displayProp={displayMobileDrawer}>
                    <NavigationMobileDrawerLogoContainer>
                        <NavigationMobileDrawerLogoImage src={Logo} />
                    </NavigationMobileDrawerLogoContainer>

                    <NavLinkContainer>
                        <NavHeader to="/" onClick={()=>toggleMobileDrawer()}>НАЧАЛО</NavHeader>
                        <NavHeader to="/about" onClick={()=>toggleMobileDrawer()}>ЗА НАС</NavHeader>
                        <NavHeader to="/projects" onClick={()=>toggleMobileDrawer()}>ПРОЕКТИ</NavHeader>
                        <NavHeader to="/contact" onClick={()=>toggleMobileDrawer()}>КОНТАКТИ</NavHeader>
                    </NavLinkContainer>
                </NavigationMobileDrawerContainer>
                
                <ScrollToTop>
                    <Routes>
                        <Route exact path='/' element={< Home />}></Route>
                        <Route exact path='/about' element={< About />}></Route>
                        <Route exact path='/projects' element={< Projects />}></Route>
                        <Route exact path='/contact' element={< Contact />}></Route>
                        <Route exact path='/individualproject/:projectId' element={< IndividualProject />}></Route>
                    </Routes>
                </ScrollToTop>
            </Router>
        </div>
    )
}
export default Navbar;