import {IndividualProjectLandingImageComponent, IndividualProjectLandingComponentDiv, 
  CenterButtonContainer, MainButton, IndividualProjectsCarouselOpenOnClick, IndividualProjectMainCarouselCol,
   IndividualProjectMainCarouseTextlCol, IndividualProjectSecondaryCarouselMagnifier,
  IndividualProjectMainCarousel, HomeCarouselExitContainer, HomeCarouselCloseButton, IndividualProjectMainContainerImage,
  IndividualProjectsImageDisplayMobile, FontAwesomeIconArrows, IndividualProjectsMagnifierContainer} from '../Styles/ContainerStyles'
import {SecondaryHeader, IndividualProjectsParagraphText, ParagraphTextIndividualProjectsAccordion, IndividualProjectsSecondaryHeader} from '../Styles/HeaderStyles'
import data from '../Data/Projects'
import dataDetails from '../Data/Details'
import dataSizes from '../Data/Sizes'
import {useParams, useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";

function IndividualProject() {
    const {projectId} = useParams()
    const [numberProjectId, setNumberProjectId] = useState(0)
    const [mainImage, setMainImage] = useState()
    const [galleryState, setGalleryState] = useState('none')
    const [itemNumber, setItemNumber] = useState(0)

    const navigate = useNavigate();
    const navigateToProjects = () => {
        navigate(`/projects`)
    }
    useEffect(() =>{
      console.log(dataDetails.details[0].info)
        setNumberProjectId(Number(projectId))
        setMainImage(`.${data.projects[projectId].mainImageUrl}`)
    },[])

    const toggleGallery =(index)=>{
      if(galleryState == 'none'){
        setGalleryState('block')
        setItemNumber(index)
      }else if(galleryState == 'block'){
        setGalleryState('none')
        setItemNumber(index)
      }
    }

    const thumbStyleSecondary = {
      height: '40px',
      margin: '0 5px',
      cursor: 'pointer',
    };

    const  thumbs = data.projects[numberProjectId].secondaryImages.map((product, index) => 
      <img 
        key={index}
        src={`.` + product}
      />        
    )      
      
    const  thumbsSecondary = data.projects[numberProjectId].secondaryImages.map((product, index) => 
      <img 
        key={index}
        src={`.` + product}
        style={thumbStyleSecondary}
      />        
    ) 
      
    const CustomArrowPrev = ({onClick }) => {
      return (
        <div onClick={onClick} className="custom-arrow-left">
          <FontAwesomeIconArrows icon={faChevronLeft} />
        </div>
      );
    };

    const CustomArrowNext = ({onClick }) => {
      return (
        <div onClick={onClick} className="custom-arrow-right">
          <FontAwesomeIconArrows icon={faChevronRight} />
        </div>
      );
    };

    return (
      <div>
        <IndividualProjectLandingImageComponent src={`.`+ data.projects[numberProjectId].mainImageUrl} />
        <IndividualProjectLandingComponentDiv>
          <SecondaryHeader marginLeft={'3%'} width={'94%'}>Мобилна къща</SecondaryHeader>
          <IndividualProjectsParagraphText width={'94%'} marginLeft={'3%'} textAlign={'center'}>
            В повечето случаи къщата е 3.30м габаритна ширина, 3.0м вътрешна ширина. 
            Височината на къщите е винаги 3.60м с гумите до най-високата точна на къщата. 
            Можем да произведем по-висока, но ще има усложнения с транспорта заради височината, когато се качи върху платформа/ремарке. 
            Банята е с вградена структура (скрито казанче в стената) по подразбиране.
            Предлагаме възможност за поставяне на вана.Осветлението е LED лунички по подразбиране.
          </IndividualProjectsParagraphText>
        </IndividualProjectLandingComponentDiv>

              <IndividualProjectMainCarouselCol>
                <Carousel
                showThumbs={true}
                renderThumbs={() => thumbs}
                showArrows={false}
                >
                  {data.projects[numberProjectId].secondaryImages.map((image, index) => (
                      <IndividualProjectMainCarousel key={index} onClick={() => toggleGallery(index)}>
                        <IndividualProjectMainContainerImage src={`.` + image} />
                      </IndividualProjectMainCarousel>
                    ))}
                </Carousel>
              </IndividualProjectMainCarouselCol>
              
              {/* Display Images For Mobile */}
              <IndividualProjectsImageDisplayMobile>
                {data.projects[numberProjectId].secondaryImages.map((image, index) => (
                  <IndividualProjectMainCarousel key={index} onClick={() => toggleGallery(index)}>
                    <IndividualProjectMainContainerImage src={`.` + image} />
                  </IndividualProjectMainCarousel>
                ))}
              </IndividualProjectsImageDisplayMobile>

              <IndividualProjectMainCarouseTextlCol>
              <IndividualProjectsSecondaryHeader marginTop={'0%'}>Детайли</IndividualProjectsSecondaryHeader>
              {dataDetails.details[numberProjectId].info.map((detail, id) =>
                <ParagraphTextIndividualProjectsAccordion width={'94%'} marginLeft={'1%'} textAlign={'left'} key={id}>
                  {detail}
                </ParagraphTextIndividualProjectsAccordion>
              )}

              <IndividualProjectsSecondaryHeader>Размер на стаите</IndividualProjectsSecondaryHeader>
                {dataSizes.sizes[numberProjectId].info.map((detail, id) =>
                  <ParagraphTextIndividualProjectsAccordion width={'94%'} marginLeft={'1%'} textAlign={'left'} key={id}>
                    {detail}
                  </ParagraphTextIndividualProjectsAccordion>
                )}
              </IndividualProjectMainCarouseTextlCol>

            

            <IndividualProjectsCarouselOpenOnClick displayProp={galleryState}>
              <HomeCarouselExitContainer>
                <HomeCarouselCloseButton onClick={() => toggleGallery(0)}>
                  <FontAwesomeIcon icon={faTimes} />
                </HomeCarouselCloseButton>
              </HomeCarouselExitContainer>

              <Carousel
                showThumbs={true}
                renderThumbs={() => thumbsSecondary}  
                selectedItem={itemNumber}
                renderArrowPrev={(clickHandler, hasPrev, label) =>
                  hasPrev && (
                    <CustomArrowPrev  onClick={clickHandler} aria-label={label} />
                  )
                }
                renderArrowNext={(clickHandler, hasPrev, label) =>
                  hasPrev && (
                    <CustomArrowNext  onClick={clickHandler} aria-label={label} />
                  )
                }     
              >
                 {data.projects[numberProjectId].secondaryImages.map((image, index) => (
                    <IndividualProjectSecondaryCarouselMagnifier 
                        imageSrc={`.` + image} 
                        imageAlt="Example"
                        key={index}
                    />
                ))}
              </Carousel>
            </IndividualProjectsCarouselOpenOnClick>
            
        <CenterButtonContainer marginTop={'4%'} marginBottom={'3%'}>
            <MainButton onClick={() => navigateToProjects()}>Обратно в проекти</MainButton>
        </CenterButtonContainer>
      </div>
    );
  }
  
  export default IndividualProject;