import styled from 'styled-components';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {GlassMagnifier, Magnifier} from "react-image-magnifiers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//Global Styles
const royalBlue = '#4169e1'
const darkBlue = '#0C1B2A'
export const RowContainer = styled.div`
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    display:flex;
    flex-direction:row;
    margin-bottom:${props => props.marginBottom};
    position:${props => props.position};
    @media only screen and (max-width:541px) and (min-width:320px){
        flex-direction:column;
    }
`
export const RowContainerCol = styled.div`
    width:50%;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-right:${props => props.marginRight};
    display:${props => props.displayProp};
    flex-direction:${props => props.flexDirection};
    justify-content:${props => props.justifyContent};
    align-items: ${props => props.alignItems};

    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%;  
       margin-top:2%;   
    }
`
export const CenterButtonContainer = styled.div`
    width:100%;
    text-align:center;
    margin-top:${props => props.marginTop};
    margin-bottom:${props => props.marginBottom};
    padding-bottom:${props => props.paddingBottom};
`
export const MainButton = styled.button`
    background-color:#0C1B2A;
    padding:10px;
    color:white;
    border-radius:10px;
    font-size:17px;
    cursor:pointer;
    border-style:none;

    @media only screen and (max-width:1201px) and (min-width:542px){
       font-size:22px;
       padding:12.5px;
    }
`
export const SecondaryButton = styled.button`
    background-color:white;
    padding:10px;
    color:#0C1B2A;
    border-radius:10px;
    font-size:17px;
    cursor:pointer;
    border-style:solid;
    border-color:#0C1B2A;
    margin-left:3%;
    margin-top:3%;
`
export const AboutUsCubesWithNumbers = styled.div`
    background-color:#0C1B2A;
    width:55px;
    height:55px;
    border-radius:10px;
    text-align:center;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-left:${props => props.marginLeft};
    width:${props => props.width};
    height:${props => props.height};
`
export const ComponentsLandingImage = styled.img`
    height:60vh;
    max-width:100%;
    width:100%;
`
export const HorizontalLine = styled.hr`
    background-color:#0C1B2A;
    border-style:solid;
    border-color:#0C1B2A;
    border-width:2px;
    border-radius:10px;
    margin-left:${props => props.marginLeft};
    width:${props => props.width};
    margin-top:${props => props.marginTop};
`
//---------Navigation Styles------------------
export const NavigationContainer = styled.div`
    width:100%;
    height:8vh;
    background-color:${darkBlue};
    display:flex;
    flex-direction:row;
    position:fixed;
    z-index:20;

    @media only screen and (max-width:541px) and (min-width:320px){
        display: none;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        display: none;
    }
`
export const NavigationContainerLandingComponent = styled.div`
    width:100%;
    height:8vh;
    background-color:transparent;
    display:flex;
    flex-direction:row;
    position:fixed;
    z-index:100;

    @media only screen and (max-width:541px) and (min-width:320px){
        display: none;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        display: none;
    }
`
export const NavigationContainerMobile = styled.div`
    @media only screen and (max-width:541px) and (min-width:320px){ 
        width:100%;
        max-width:100%;
        height:8vh;
        background-color:${darkBlue};
        display:flex;
        flex-direction:row;
        position:fixed;
        z-index:20;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        max-width:100%;
        height:9vh;
        background-color:${darkBlue};
        display:flex;
        flex-direction:row;
        position:fixed;
        z-index:20;
    }
`
export const NavigationMobileDrawerContainer = styled.div`
    width:85%;
    background-color:#0C1B2Aba;
    display:${props => props.displayProp? "none" : "block"};
    position:fixed;
    height:100vh;
    z-index:25;
`
export const NavigationMobileDrawerLogoContainer = styled.div`
    width:85%;
`
export const NavigationMobileDrawerLogoImage = styled.img`
    width:35%;
    margin-left:7.5%;
    margin-top:7.5%;
`
export const NavigationContainerLandingComponentMobile = styled.div`
    @media only screen and (max-width:541px) and (min-width:320px){ 
        width:100%;
        max-width:100%;
        height:9vh;
        background-color:transparent;
        display:flex;
        flex-direction:row;
        position:fixed;
        z-index:100;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        max-width:100%;
        height:8vh;
        background-color:transparent;
        display:flex;
        flex-direction:row;
        position:fixed;
        z-index:100;
    }
`

export const NavigationLogoContainer = styled.div`
    width:30%;
    margin-left:1%;
    @media only screen and (max-width:541px) and (min-width:320px){
        width:30%;
        margin-left:2.5%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:30%;
        margin-left:2.5%;
    }
`
export const LogoImage = styled.img`
    height: 7.3vh;
    width:13%;
    margin-top:2%;
    
    @media only screen and (max-width:541px) and (min-width:320px){
        height: 6.5vh;
        width:55px;
        margin-top:10%;
        display:${props => props.displayProp? "block" : "none"};
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        height: 7.5vh;
        width:75px;
        margin-top:6%;
        display:${props => props.displayProp? "block" : "none"};
    }
`
export const NavigationHamburgerIconButton = styled.button`
    @media only screen and (max-width:541px) and (min-width:320px){
        width:30%;
        background-color:transparent;
        border-style:none;
        margin-left:30%;
        text-align:right;
        padding-right:0px;
        margin-right:0px;
    }

    @media only screen and (min-width:1202px){
        display: none;
    }
   
    @media only screen and (max-width:1201px) and (min-width:542px){
        width:30%;
        background-color:transparent;
        border-style:none;
        margin-left:30%;
        text-align:right;
        padding-right:0px;
        margin-right:0px;
    }
`
export const NavLinkContainer = styled.div`
    width:30%;
    justify-content: center;
    align-items: center;
    margin-top:1.25%; //on a bigger desktop 1.5 is better for media querry
    margin-left:38.5%;

    @media only screen and (max-width:541px) and (min-width:320px){
        display:flex;
        flex-direction:column;
        align-items:normal;
        justify-content:start;
        text-align:left;
        margin-left:7.5%;
        width:100%;
        margin-top:0%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        display:flex;
        flex-direction:column;
        align-items:normal;
        justify-content:start;
        text-align:left;
        margin-left:7.5%;
        width:100%;
        margin-top:0%;
    }
   
`
//---------Navigation Styles End----------
//---------Home Styles--------------------
export const HomeImageContainer = styled.img`
    width:${props => props.width};
    height:${props => props.height};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-right:${props => props.marginRight};
    cursor:${props =>props.cursorProp};
    border-radius:10px;
    bottom:${props => props.bottomProp};
    position:${props => props.position};
`
//Landing Component 
export const HomeLandingImageComponent = styled.img`
    width:100%;
    height:105vh;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:100%;
        height:70vh;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        height:70vh;
    }
`
export const HomeLandingComponentContainer = styled.div`
    position:absolute;
    width:40%;
    margin-top:-30%;
    margin-left:7.5%;
    z-index:10;
    text-align:center;

    @media only screen and (max-width:541px) and (min-width:320px){
        display:none;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:80%;
        margin-left:10%;
        margin-top:-28%;
    }

`
//Chose us component Styles
export const ChooseUsRowContainer = styled.div`
    width:80%;
    margin-left:10%;
    margin-top:2%;
    display:flex;
    flex-direction:row;

    @media only screen and (max-width:541px) and (min-width:320px){
       flex-direction:column;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        flex-direction:column;
    }
`
export const ChooseUsColLeft = styled.div`
    width:50%;
    text-align:center;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
    }
`
export const ChooseUsColRight = styled.div`
    width:50%;
    text-align:center;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%;
    }

     @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        margin-top:3%;
    }
`
export const ChoseUsImage = styled.img`
    height:45px;
    width:18%;
    margin-top:2.5%;
`
export const ChoseUsColDiv = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
`
export const ChoseUsColDivInner = styled.div`
    width:50%;
    text-align:center;
`
//About us Component
export const HomeAboutUsContainer = styled.div`
    width:100%;
    margin-top:5%;
    background-color:#D2D7DB;
`
export const HomeAboutUsComponentNumbersRow = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    margin-bottom:${props => props.marginBottom};
`
export const HomeRowContainerCol = styled.div`
    width:50%;
    text-align:${props => props.textAlign};
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-right:${props => props.marginRight};
    display:${props => props.displayProp};
    flex-direction:${props => props.flexDirection};
    justify-content:${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    @media only screen and (max-width:541px) and (min-width:320px){
       display:none;
    }
`
export const HomeRowContainerColMobile = styled.div`
    display:none;
    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%;
       display:block;
    }
`
export const HomeAboutUsImageContainer = styled.img`
    width:${props => props.width};
    height:${props => props.height};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-right:${props => props.marginRight};
    cursor:${props =>props.cursorProp};
    border-radius:10px;
    bottom:${props => props.bottomProp};
    position:${props => props.position};

    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%; 
       height:40vh;    
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
       width:100%; 
       height:30vh;    
    }
`
export const HomeAboutUsButton = styled.button`
    background-color:#0C1B2A;
    padding:10px;
    color:white;
    border-radius:10px;
    font-size:17px;
    cursor:pointer;
    border-style:none;

    @media only screen and (max-width:541px) and (min-width:320px){
       margin-bottom:4%;
       margin-top:2%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        margin-bottom:2%;
        margin-top:2%;   
    }
`
export const HomeAboutUsComponentNumbersCol = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    align-items: center;
    margin-bottom:3%;
`

//Recent Projects Component Styles
export const HomeRecentProjectsRowContainer = styled.div`
    width:80%;
    margin-left:10%;
    display:flex;
    flex-direction:row;

    @media only screen and (max-width:541px) and (min-width:320px){
      width:90%;
      margin-left:5%;
      display:flex;
      flex-direction:column;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
      width:90%;
      margin-left:5%;
      display:flex;
      flex-direction:column;  
    }
`
export const HomeRecentProjectsSmallCol = styled.div`
    width:23.5%;

    @media only screen and (max-width:541px) and (min-width:320px){
      width:100%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;  
    }
`
export const HomeRecentProjectsBigCol = styled.div`
    width:48%;
    margin-left:2.5%;
    margin-right:2.5%;

    @media only screen and (max-width:541px) and (min-width:320px){
      width:100%;
      margin-left:0;
      margin-right:0;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
      width:100%;
      margin-left:0;
      margin-right:0;  
    }
`
export const HomeRecentProjectsBigColInnerRow = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    margin-bottom:${props => props.marginBottom};
    position:relative;

    @media only screen and (max-width:541px) and (min-width:320px){
      display:flex;
      flex-direction:column;
      margin-bottom:0;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
      display:flex;
      flex-direction:column;
      margin-bottom:0;  
    }
`
export const HomeRecentProjectsImageContainer = styled.img`
    width:${props => props.width};
    height:${props => props.height};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-right:${props => props.marginRight};
    cursor:${props =>props.cursorProp};
    border-radius:10px;
    bottom:${props => props.bottomProp};
    position:${props => props.position};

    @media only screen and (max-width:541px) and (min-width:320px){
      width:100%;
      height:30vh;
      margin-bottom:5%;
      position:relative;
      margin-left:0%;
      margin-top:0%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
      width:100%;
      height:40vh;
      margin-bottom:5%;
      position:relative;
      margin-left:0%;
      margin-top:0%;  
    }
`
// Testimonials Component
export const HomeTestimonialsContainer = styled.div`
    width:100%;
    margin-top:5%;
    background-color:#D2D7DB;
    padding-bottom:3%;
`
export const TestimonialsRowContainer = styled.div`
    width:90%;
    margin-left:5%;
    display:flex;
    flex-direction:row;

    @media only screen and (max-width:541px) and (min-width:320px){
        display:flex;
        display:column;
        margin-top:-7%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:90%;
        margin-left:5%;
        display:flex;
        flex-direction:row;  
    }
`
export const HomeTestimonialsSwiperContainer = styled.div`
    width:100%;
    max-height:95%;
    background-color:none;
    padding-top:10%;
    padding-bottom:7%;
    margin-bottom:10%;
    text-align:center;

    @media only screen and (max-width:541px) and (min-width:320px){
        padding-top:10%;
        padding-bottom:7%;
        margin-bottom:10%;
        text-align:center;
    }
`
export const HomeTestimonialsSwiperInnerContainer = styled.div`
    width:85%;
    margin-left:7.5%;
    border-radius:30px 0px 30px 0px;
    height:30vh;
    background-color:white;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:80%;
        margin-left:10%;
        border-radius:30px 0px 30px 0px;
        height:25vh;
        background-color:white;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:80%;
        margin-left:10%;
        border-radius:30px 0px 30px 0px;
        height:20vh;
        background-color:white; 
    }
`
export const HomeTestimonialsCircleBig = styled.div`
    width:60px;
    height:60px;
    margin-top:-7%;
    margin-left:85%;
    border-radius:50px;
    position:fixed;
    background-color:${darkBlue};
    text-align:center;
    z-index:50;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:50px;
        height:50px;
        margin-top:-7%;
        margin-left:82.5%;
        border-radius:50px;
        position:fixed;
        background-color:${darkBlue};
        text-align:center;
        z-index:50;
    }
`
export const HomeTestimonialsCircleSmall = styled.div`
    width:45px;
    height:45px;
    margin-top:-7%;
    margin-left:2.5%;
    border-radius:50px;
    position:fixed;
    background-color:${darkBlue};
    text-align:center;
    z-index:50;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:40px;
        height:40px;
        margin-top:-7%;
        margin-left:5%;
        border-radius:50px;
        position:fixed;
        background-color:${darkBlue};
        text-align:center;
        z-index:50;
    }
`
export const HomeQuotesImage = styled.img`
    width:${props => props.width};
    margin-top:${props => props.marginTop};
`
//Contact us Component
export const HomeContactUsContainer = styled.div`
    width:100%;
    text-align:center;
    margin-top:5%;
    margin-bottom:5%;
`
//------------Home Component Styles End-------------
//------------About us Component Styles-------------
export const RowContainerAboutUsComponent = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    margin-top:4%;
    
    @media only screen and (max-width:541px) and (min-width:320px){
        flex-direction:column;       
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        flex-direction:column;
    }
`
export const AboutUsRowContainerCol = styled.div`
    width:50%;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:100%;
        margin-top:7%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        margin-top:4%;
    }
`
export const HorizontalLineAboutUs = styled.hr`
    background-color:#0C1B2A;
    border-style:solid;
    border-color:#0C1B2A;
    border-width:2px;
    border-radius:10px;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:70%;
        margin-left:15%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:70%;
        margin-left:15%;
    }
`
export const RowContainerAboutUsOpacity = styled.div`
    width:70%;
    margin-left:15%;
    margin-top:-20%;
    display:flex;
    flex-direction:row;
    position:relative;
     margin-bottom:12.5%;

    @media only screen and (max-width:541px) and (min-width:320px){
        flex-direction:column;
        margin-top:-48vh;
        margin-bottom:8vh;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:80%;
        margin-left:10%;
        margin-top:-30%;
    }
`
export const AboutUsOpacityHeadersContainer = styled.div`
    position:absolute;
    width:100%;
`
export const RowContainerColAboutUsOpacity = styled.div`
    width:33%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:100%;
        flex-direction:column;
    }
`
export const AboutUsOpacityContainerCubesWithNumbers = styled.div`
    background-color:#0C1B2A;
    border-radius:10px;
    text-align:center;
    display:flex;
    align-items: center;
    justify-content: center;
    width:12vh;
    height:12vh;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:9vh;
        height:9vh;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:9.5vh;
        height:9.5vh;
    }
`
export const AboutUsServicesImageContainer = styled.div`
    text-align:center;
`
export const AboutUsServicesImage = styled.img`
    width:60px;
    text-align:center;
`
export const AboutUsOpacityImageContainer = styled.div`
    width:100%;
    margin-top:5%;
`
export const AboutUsOpacityImage = styled.img`
    width:100%;
    max-height:60vh;

    @media only screen and (max-width:541px) and (min-width:320px){
        min-height:65vh;
        max-height:65vh;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        min-height:40vh;
        max-height:40vh;
    }
`
export const AboutUsAcordionContainer = styled.div`
    width:90%;
    margin-left:5%;
    margin-top:3%;
`
export const AboutUsAccordionDetails = styled(AccordionDetails)`
    background-color:#D2D7DB;
`
export const ExpandMoreIconColor = styled(ExpandMoreIcon)`
    color:white;
`
//------------About us Component Styles End---------
//------------Projects Component Styles-------------
export const ProjectsMainContainer = styled.div`
    width:90%;
    margin-left:5%;
`
export const ProjectsRowContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    margin-top:${props => props.marginTop};

    @media only screen and (max-width:541px) and (min-width:320px){
        flex-direction:column;
        width:95%;
        margin-left:2.5%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        flex-direction:column;
        width:95%;
        margin-left:2.5%;
    }
`
export const ProjectsImageContainerBig = styled.img`
    width:${props => props.width};
    height:${props => props.height};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-right:${props => props.marginRight};
    cursor:${props =>props.cursorProp};
    border-radius:10px;
    bottom:${props => props.bottomProp};
    position:${props => props.position};

    @media only screen and (max-width:541px) and (min-width:320px){
        width:100%;
        height:30vh;
        margin-bottom:5%;
        margin-top:0%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        height:35vh;
        margin-bottom:3%;
        margin-top:0%;
    }
`
export const ProjectsImageContainerSmall = styled.img`
    width:${props => props.width};
    height:${props => props.height};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    margin-right:${props => props.marginRight};
    cursor:${props =>props.cursorProp};
    border-radius:10px;
    bottom:${props => props.bottomProp};
    position:${props => props.position};

    @media only screen and (max-width:541px) and (min-width:320px){
        width:100%;
        height:30vh;
        margin-bottom:5%;
        margin-top:0%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        height:35vh;
        margin-bottom:3%;
        margin-top:0%;
    }
`
//------------Projects Component Styles End---------
//------------Indidvidual Project Component Styles-------------
export const IndividualProjectLandingImageComponent = styled.img`
    width:100%;
    height:90vh;

    @media only screen and (max-width:541px) and (min-width:320px){
        height:50vh;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        height:55vh; 
    }
`
export const IndividualProjectLandingComponentDiv = styled.div`
    width:40%;
    margin-left:30%;
    border-style:solid;
    border-color:${darkBlue};
    border-width:2px;
    background-color:white;
    border-radius:10px;
    height:auto;
    margin-top:-10%;
    z-index:5;
    position:absolute;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:90%;
        margin-left:5%;
        margin-top:10%;
        height:auto;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:70%;
        margin-left:15%;
        margin-top:10%;
        height:auto; 
    }
`
export const IndividualProjectMainCarouselCol = styled.div`
    width:55%;
    margin-left:22.5%;
    margin-top:8%;
    @media only screen and (max-width:541px) and (min-width:320px){
       display:none;
    }
    @media only screen and (max-width:1201px) and (min-width:542px){
        width:90%;
        margin-left:5%;
        margin-top:60%;
    }
`
export const IndividualProjectMainCarousel = styled.div`
    cursor: pointer;
`
export const IndividualProjectMainContainerImage = styled.img`
    border-radius:15px;
     @media only screen and (max-width:541px) and (min-width:320px){
        width:100%;
        margin-bottom:5%;
    }
`
export const IndividualProjectMainCarouseTextlCol = styled.div`
    width:55%;
    margin-left:22.5%;
    @media only screen and (max-width:541px) and (min-width:320px){
        width:90%;
        margin-left:5%;
    }
     @media only screen and (max-width:1201px) and (min-width:542px){
        width:90%;
        margin-left:5%;
    }
`
export const IndividualProjectsCarouselOpenOnClick = styled.div`
     display:${props => props.displayProp};
     background-color:white;
     position:fixed;
     width:100%;
     height:100vh;
     top:0;
     z-index:100;
     @media only screen and (max-width:541px) and (min-width:320px){
        display:none;
    }
`
export const HomeCarouselExitContainer = styled.div`
    width:100%;
    height:10vh;
    margin-left:95%;
    @media only screen and (max-width:541px) and (min-width:320px){
        margin-left:85%;
        height:25vh;
    }
    @media only screen and (max-width:1201px) and (min-width:542px){
        margin-left:92.5%;
        height:15vh;
    }
`
export const HomeCarouselCloseButton = styled.button`
    font-size:35px;
    margin-top:1.5%;
    background-color:white;
    border-style:none;
    cursor: pointer;
    @media only screen and (max-width:541px) and (min-width:320px){
       margin-top:10%;
    }
    @media only screen and (max-width:1201px) and (min-width:542px){
        margin-top:6%;
    }
`
export const IndividualProjectSecondaryCarouselMagnifier = styled(GlassMagnifier)`
    width:65%;
    margin-left:17.5%;
    @media only screen and (max-width:541px) and (min-width:320px){
        width:80%;
        margin-left:10%;
    }
    @media only screen and (max-width:1201px) and (min-width:542px){
        width:80%;
        margin-left:10%;
        margin-top:6%;
    }
`
export const IndividualProjectsImageDisplayMobile = styled.div`
    display:none;
    @media only screen and (max-width:541px) and (min-width:320px){
        display:block;
        width:90%;
        margin-left:5%;
        margin-top:96%;
    }
`
export const FontAwesomeIconArrows = styled(FontAwesomeIcon)`
    color:white;
    font-size:30px;
`
export const IndividualProjectsMagnifierContainer = styled.div`

`
//------------Individual Project Component Styles End----------
//------------Contact Us Component Styles-----------
export const ContactUsMainRowContainer = styled.div`
    width:60%;
    margin-left:20%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

    @media only screen and (max-width:541px) and (min-width:320px){
       display:none;
    } 

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:90%;
        margin-left:5%;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
    }
`
export const ContactUsMainRowContainerMobile = styled.div`
    display:none;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:90%;
       margin-left:5%;
       display:flex;
       flex-direction:column;
       margin-top:4%;
    } 
`
export const ContactUsHeaderRowContainer = styled.div`
    width:${props => props.width};
    margin-left:${props => props.marginLeft};
    margin-top:${props => props.marginTop};
    display:flex;
    flex-direction:row;
    margin-bottom:${props => props.marginBottom};
    position:${props => props.position};

    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%;
       margin-top:4%;
       justify-content:center; 
       flex-direction:column; 
    } 

    @media only screen and (max-width:1201px) and (min-width:542px){
       width:100%;
       margin-top:4%;
       justify-content:center; 
       flex-direction:column;
    }
`
export const ContactUsRowFirstTwoDetails = styled.div`
    width:50%;
    display:flex;
    flex-direction:row;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%;
       display:flex;
       flex-direction:row;
       margin-top:4%;
    } 
`
export const ContactUsRowContainerCol = styled.div`
    min-width:20%;
    max-width:20%;

    @media only screen and (max-width:541px) and (min-width:320px){
        min-width:50%;
        max-width:50%;
        margin-top:4%;
        text-align:center;
        justify-content:center;
    }  
`
export const ContactUsHorizontalLine = styled.div`
    background-color:#0C1B2A;
    border-style:solid;
    border-color:#0C1B2A;
    border-width:2px;
    border-radius:10px;
    width:70%;
    margin-left:15%;
    margin-top:1%;
    margin-bottom:2%;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:90%;
        margin-left:5%;
        margin-top:5%;
        margin-bottom:5%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:90%;
        margin-left:5%;
        margin-top:5%;
        margin-bottom:5%;
    }
`
export const ContactUsMapContainer = styled.div`
    width:70%;
    margin-left:15%;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:90%;
        margin-left:5%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:90%;
        margin-left:5%;
    }
`
export const ContactUsIFrame=styled.iframe`
    width:100%;
    height:55vh;
    border-style:none;

    @media only screen and (max-width:541px) and (min-width:320px){
        height:45vh;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        height:40vh;
    }
`
export const ContactUsFormContainer = styled.div`
    width:35%;
    margin-left:32.5%;

    @media only screen and (max-width:541px) and (min-width:320px){
        width:90%;
        margin-left:5%;
    }

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:90%;
        margin-left:5%;
    }
`
export const ContactUsFormLabel = styled.label`
    width:100%;
    display:flex;
    flex-direction:column;
    margin-bottom:1%;
`
export const ContactUsFormInput = styled.input`
    border-color:${darkBlue};
    border-radius:5px;
    height:27px;
    border-width:2px;
`
export const ContactUsFormTextarea = styled.textarea`
    border-style:solid; 
    border-color:${darkBlue}!important;
    border-width:2px;
    border-radius:5px;
    min-width:99%;
    max-width:99%;
    min-height:92px;
`

//------------Contact Us Component Styles End-------
//------------Footer Styles-------------------------
export const FooterContainer = styled.div`
    width:100%;
    background-color:#0C1B2A;
`
export const FooterInnerContainer = styled.div`
    width:90%;
    margin-left:5%;
    padding-top:2%;
    padding-bottom:3%;
`
export const FooterRowContainer = styled.div`
    width:70%;
    display:flex;
    flex-direction:row;
    margin-top:2%;

    @media only screen and (max-width:541px) and (min-width:320px){
       width:100%;
       display:flex;
       flex-direction:column;
       margin-top:4%;
    }  
    
    @media only screen and (max-width:1201px) and (min-width:542px){
        width:100%;
        display:flex;
        flex-direction:row;
        margin-top:2%;
    }
`
export const FooterRowColumns = styled.div`
    text-align:left;
    width:50%;

    @media only screen and (max-width:541px) and (min-width:320px){
        margin-bottom:5%;
        width:100%;
    } 

    @media only screen and (max-width:1201px) and (min-width:542px){
        max-width:26.5%;
    }
`
export const FooterRowMidColumn = styled.div`
    text-align:left;
    width:50%;
    margin-left:-5%;
    margin-right:5%;

    @media only screen and (max-width:541px) and (min-width:320px){
        margin-left:0%;
        margin-bottom:5%;
        width:100%;
    } 

    @media only screen and (max-width:1201px) and (min-width:542px){
        width:46%;
        margin-left:3%;
        margin-right:2%;
    }
`
export const FooterRowContainerInner = styled.div`
    width:60%;
    margin-top:2%;
    display:flex;
    flex-direction:row;
`
export const FooterSocialIcons = styled.img`
    height:15px;
    width:25px;
    margin-top:2.5%;
    margin-right:1%;
`
//------------Footer Styles End---------------------