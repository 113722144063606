const dataDetails = {
    details:[
        {
            id:1,
            info:[
                "- Изолация - 10см минерална вата 35кг/м3",
                "- Фасада - VOX Profile Viffront Unicolor White (едноцветен бял) Бюджетен клас",
                "- Покрив - RAL7016 антрацит, едноскатен с наклон назад",
                "- Прозорци - PVC 5 камерна Profilink, двоен стъклопакет, 4К стъкла, антрацит",
                "- Врата - Алумииева 40мм каса, двоен стъклопакет, 4К стъкла, антрацит"
            ]
        },
        {
            id:2,
            info:[
                "- Изолация - 5см минерална вата 35кг/м3",
                "- Фасада - VOX Profile Viffront Golden Oak и Pine (Златен Дъб и Бор) Бюджетен клас",
                "- Покрив - RAL8017 шоколад, едноскатен с наклон назад",
                "- Прозорци - PVC 5 камерна Profilink, двоен стъклопакет, 4К стъкла, златен дъб",
                "- Врата - Алумииева 40мм каса, двоен стъклопакет, 4К стъкла, златен дъб"
            ]
        },
        {
            id:3,
            info:[
                "- Изолация - 10см каменна вата 75кг/м3",
                "- Фасада - VOX Profile Viffront Snowy Oak (Снежен Дъб) Бюджетен клас",
                "- Покрив - RAL8017 шоколад, едноскатен с наклон назад",
                "- Прозорци - PVC 5 камерна Profilink, двоен стъклопакет, 4К стъкла, орех",
                "- Врата - Алумииева 40мм каса, плъзгаща, двоен стъклопакет, 4К стъкла, орех"
            ]
        },
        {
            id:4,
            info:[
                "- Изолация - 10см минерална вата 35кг/м3",
                "- Фасада – Dumapan DumaClin T8 с PU пяна Висок клас",
                "- Покрив - RAL8003 бял, едноскатен с наклон назад",
                "- Прозорци - PVC 5 камерна Profilink, двоен стъклопакет, 4К стъкла, бели",
                "- Врата - Алумииева 40мм каса, двоен стъклопакет, 4К стъкла, бяла"
            ]
        },
        {
            id:5,
            info:[               
                "- Изолация - 10см каменна вата 75кг/м3",
                "- Фасада - VOX Profile Viffront Unicolor Beige (едноцветен бежов) Бюджетен клас",
                "- Покрив - RAL8017 шоколад, едноскатен с наклон назад",
                "- Прозорци - PVC 5 камерна Profilink, двоен стъклопакет, 4К стъкла, орех",
                "- Врата - Алумииева 40мм каса, двоен стъклопакет, 4К стъкла, орех"             
            ]
        },
        {
            id:6,
            info:[
                "- Изолация - 10см минерална вата 35кг/м3",
                "- Фасада - VOX Profile Viffront Snowy Oak (Снежен дъб) Бюджетен клас",
                "- Покрив – RAL8017 шоколад, едноскатен с наклон назад",
                "- Прозорци - PVC 5 камерна Profilink, двоен стъклопакет, 4К стъкла, орех",
                "- Врата - Алумииева 40мм каса, двоен стъклопакет, 4К стъкла, орех"
            ]
        },
        {
            id:7,
            info:[
                "Изолация – 10см каменна вата 75кг/м3",
                "Фасада – VOX Profile Kerrafront Beige (бежов) най-висок клас",
                "Покрив – RAL3002 бял, едноскатен с наклон назад",
                "Дограма – алуминиева врата с термомост, PVC прозорци, двоен стъклопакет 4К стъкла"
            ]
        },
        {
            id:8,
            info:[
                "Изолация – 10см каменна вата 75кг/м3",
                "Фасада – U-Plast Stone Brick Beige (бюджетен клас)",
                "Покрив – RAL8017 шоколад, едноскатен с наклон назад",
                "Дограма – алуминиева врата, PVC прозорци, двоен стъклопакет 4К стъкла"
            ]
        },
        {
            id:9,
            info:[
                "Изолация – 10см на стени, 20см на под и таван, ЕКО овча вълна 35кг/м3 (специално изискване, без абразив)",
                "Фасада – дървена ламперия от масивна дървесина тип трупи",
                "Таван – дървена ламперия от масивна дървесина тип широко перо",
                "Стени – дървена ламперия от масивна дървесина тип широко перо",
                "Под – дървено дюшеме от масивна дървесина",
                "Покрив – равен с надстройка за поставяне на керамични керемиди",
                "Дограма –PVC вради и прозорци, цвят чам, троен стъклопакет 4К-бяло-К стъкло"
            ]
        }

    ]
}

export default dataDetails