const data = {
    projects:[
        {
            id:1,
            mainImageUrl: "./Images/Project1/main.jpg",
            secondaryImages:[
                "./Images/Project1/1.png",
                "./Images/Project1/2.png",
                "./Images/Project1/3.png",
                "./Images/Project1/4.png",
                "./Images/Project1/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project1/1m.png",
                "./Images/Project1/2m.png",
                "./Images/Project1/3m.png",
                "./Images/Project1/4m.png",
                "./Images/Project1/5m.png"
            ]
        },
        {
            id:2,
            mainImageUrl: "./Images/Project2/main.jpg",
            secondaryImages:[
                "./Images/Project2/1.png",
                "./Images/Project2/2.png",
                "./Images/Project2/3.png",
                "./Images/Project2/4.png",
                "./Images/Project2/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project2/1m.png",
                "./Images/Project2/2m.png",
                "./Images/Project2/3m.png",
                "./Images/Project2/4m.png",
                "./Images/Project2/5m.png"
            ]
        },
        {   id:3,
            mainImageUrl: "./Images/Project3/main.jpg",
            secondaryImages:[
                "./Images/Project3/1.png",
                "./Images/Project3/2.png",
                "./Images/Project3/3.png",
                "./Images/Project3/4.png",
                "./Images/Project3/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project3/1m.png",
                "./Images/Project3/2m.png",
                "./Images/Project3/3m.png",
                "./Images/Project3/4m.png",
                "./Images/Project5/5m.png"
            ]
        },
        {
            id:4,
            mainImageUrl: "./Images/Project4/main.jpg",
            secondaryImages:[
                "./Images/Project4/1.png",
                "./Images/Project4/2.png",
                "./Images/Project4/3.png",
                "./Images/Project4/4.png",
                "./Images/Project4/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project4/1m.png",
                "./Images/Project4/2m.png",
                "./Images/Project4/3m.png",
                "./Images/Project4/4m.png",
                "./Images/Project4/5m.png"
            ]
        },
        {
            id:5,
            mainImageUrl: "./Images/Project5/main.jpg",
            secondaryImages:[
                "./Images/Project5/1.png",
                "./Images/Project5/2.png",
                "./Images/Project5/3.png",
                "./Images/Project5/4.png",
                "./Images/Project5/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project5/1m.png",
                "./Images/Project5/2m.png",
                "./Images/Project5/3m.png",
                "./Images/Project5/4m.png",
                "./Images/Project5/5m.png"
            ]
        },
        {
            id:6,
            mainImageUrl: "./Images/Project6/main.jpg",
            secondaryImages:[
                "./Images/Project6/1.png",
                "./Images/Project6/2.png",
                "./Images/Project6/3.png",
                "./Images/Project6/4.png",
                "./Images/Project6/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project6/1m.png",
                "./Images/Project6/2m.png",
                "./Images/Project6/3m.png",
                "./Images/Project6/4m.png",
                "./Images/Project6/5m.png"
            ]
            
        },
        {
            id:7,
            mainImageUrl: "./Images/Project7/main.jpg",
            secondaryImages:[
                "./Images/Project7/1.png",
                "./Images/Project7/2.png",
                "./Images/Project7/3.png",
                "./Images/Project7/4.png",
                "./Images/Project7/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project7/1m.png",
                "./Images/Project7/2m.png",
                "./Images/Project7/3m.png",
                "./Images/Project7/4m.png",
                "./Images/Project7/5m.png"
            ]           
        },
        {
            id:8,
            mainImageUrl: "./Images/Project8/main.jpg",
            secondaryImages:[
                "./Images/Project8/1.png",
                "./Images/Project8/2.png",
                "./Images/Project8/3.png",
                "./Images/Project8/4.png",
                "./Images/Project8/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project8/1m.png",
                "./Images/Project8/2m.png",
                "./Images/Project8/3m.png",
                "./Images/Project8/4m.png",
                "./Images/Project8/5m.png"
            ]           
        },
        {
            id:9,
            mainImageUrl: "./Images/Project9/main.jpg",
            secondaryImages:[
                "./Images/Project9/1.png",
                "./Images/Project9/2.png",
                "./Images/Project9/3.png",
                "./Images/Project9/4.png",
                "./Images/Project9/5.png"
            ],
            secondaryImagesMobile:[
                "./Images/Project9/1m.png",
                "./Images/Project9/2m.png",
                "./Images/Project9/3m.png",
                "./Images/Project9/4m.png",
                "./Images/Project9/5m.png"
            ]           
        }
        

    ]
}

export default data