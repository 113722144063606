import Landing from '../Images/ProjectsOpacity.png'
import {ComponentsLandingImage, HomeContactUsContainer, MainButton, 
  ProjectsMainContainer, ProjectsRowContainer, HomeImageContainer, ProjectsImageContainerBig, ProjectsImageContainerSmall} from '../Styles/ContainerStyles'
import {ComponentLandingHeader, MainHeader} from '../Styles/HeaderStyles'
import data from '../Data/Projects'
import {useNavigate} from 'react-router-dom';

function Projects() {
    const navigate = useNavigate();
    const navigateToProject = (projectId) => {
        navigate(`/individualproject/${projectId}`)
    }

    const navigateToContact = () => {
        navigate(`/contact`)
    }
    return (
      <div>
        <ComponentsLandingImage src={Landing} />
        <ComponentLandingHeader>Проекти</ComponentLandingHeader>
        <MainHeader>Завършени Проекти</MainHeader>

        <ProjectsMainContainer>
          <ProjectsRowContainer>
            <ProjectsImageContainerBig  onClick={() => navigateToProject(0)} src={data.projects[0].mainImageUrl} width={'49.25%'} height={'53vh'} marginRight={'0.75%'}  cursorProp={'pointer'}/>
            <ProjectsImageContainerSmall  onClick={() => navigateToProject(1)} src={data.projects[1].mainImageUrl} width={'49.25%'} height={'40vh'} marginLeft={'0.75%'}  cursorProp={'pointer'}/>
          </ProjectsRowContainer>

          <ProjectsRowContainer >
            <ProjectsImageContainerSmall  onClick={() => navigateToProject(2)} src={data.projects[2].mainImageUrl} width={'49.25%'} height={'40vh'} marginRight={'0.75%'}  cursorProp={'pointer'} marginTop={'1.5%'}/>
            <ProjectsImageContainerBig  onClick={() => navigateToProject(3)} src={data.projects[3].mainImageUrl} width={'49.25%'} height={'53vh'} marginLeft={'0.75%'}  cursorProp={'pointer'} marginTop={'-6.25%'}/>
          </ProjectsRowContainer>

          <ProjectsRowContainer>
            <ProjectsImageContainerBig  onClick={() => navigateToProject(4)} src={data.projects[4].mainImageUrl} width={'49.25%'} height={'53vh'} marginRight={'0.75%'}  cursorProp={'pointer'} marginTop={'1.5%'}/>
            <ProjectsImageContainerSmall  onClick={() => navigateToProject(5)} src={data.projects[5].mainImageUrl} width={'49.25%'} height={'40vh'} marginLeft={'0.75%'}  cursorProp={'pointer'} marginTop={'1.5%'}/>
          </ProjectsRowContainer>

          <ProjectsRowContainer >
            <ProjectsImageContainerSmall  onClick={() => navigateToProject(6)} src={data.projects[6].mainImageUrl} width={'49.25%'} height={'40vh'} marginRight={'0.75%'}  cursorProp={'pointer'} marginTop={'1.5%'}/>
            <ProjectsImageContainerBig  onClick={() => navigateToProject(7)} src={data.projects[7].mainImageUrl} width={'49.25%'} height={'53vh'} marginLeft={'0.75%'}  cursorProp={'pointer'} marginTop={'-6.25%'}/>
        </ProjectsRowContainer>

        <ProjectsRowContainer>
            <ProjectsImageContainerBig  onClick={() => navigateToProject(8)} src={data.projects[8].mainImageUrl} width={'49.25%'} height={'53vh'} marginRight={'0.75%'}  cursorProp={'pointer'} marginTop={'1.5%'}/>
            {/* <ProjectsImageContainerSmall  onClick={() => navigateToProject(5)} src={data.projects[5].mainImageUrl} width={'49.25%'} height={'40vh'} marginLeft={'0.75%'}  cursorProp={'pointer'} marginTop={'1.5%'}/> */}
          </ProjectsRowContainer>

        </ProjectsMainContainer>

      

        {/* Contact us Component */}
        <HomeContactUsContainer>
          <MainHeader>Харесвате това което виждате?</MainHeader>
          <MainButton onClick={() => navigateToContact()}>Свържете се</MainButton>
        </HomeContactUsContainer>
      </div>
    );
  }
  
  export default Projects;